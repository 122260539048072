import { useHistory } from 'react-router-dom'

import { PreviewPix } from './PreviewPix'
import { PreviewFrame } from './PreviewFrame'
import { PreviewMessage } from './PreviewMessage'

import { isProduction } from '../../settings.js'
import { PreviewBankwire } from './PreviewBankwire/PreviewBankwire'
import { PreviewInstructions } from './PreviewInstructions'
import { isMobileDevice, redirectToExternalUrl } from 'mmfintech-commons'

export const DepositResult = ({ response, logEvent, session, sessionId, startPolling }) => {
  const { result, resultType, status, processingAmount, processingCurrency } = response || {}

  const history = useHistory()

  if (status === 'FAILED') {
    history.push('/fail')
    return
  }

  switch (resultType) {
    // case 'INTERNAL_EXECUTION':
    // case 'CRYPTO_ADDRESS_DETAILS':
    // case 'CHECKOUT_CRYPTO_ADDRESS_DETAILS':

    case 'INSTRUCTIONS':
      return (
        <PreviewInstructions
          result={{ ...result, processingAmount, processingCurrency }}
          session={session}
          startPolling={startPolling}
        />
      )

    case 'MESSAGE':
      return <PreviewMessage result={result} />

    case 'BANKWIRE_DETAILS':
      logEvent('manual_bank_transfer_details_shown')
      return <PreviewBankwire result={result} session={session} sessionId={sessionId} />

    case 'REDIRECT_URL':
      logEvent('redirected_to_provider')
      if (typeof result === 'string') {
        redirectToExternalUrl(result)
      } else {
        const { redirectUrl } = result || {}
        redirectToExternalUrl(redirectUrl)
      }
      break

    case 'QR_CODE':
      return <PreviewPix value={result} />

    case 'IFRAME_URL':
      if (isMobileDevice()) {
        logEvent('redirected_to_provider')
        redirectToExternalUrl(result)
      } else {
        logEvent('open_provider_in_frame')
        return <PreviewFrame result={result} />
      }
      break

    default:
      if (!isProduction && resultType) {
        console.log(`DepositResult: Unsupported result type "${resultType}"`)
      }
  }

  if (status === 'PROCESSED') {
    history.push('/success')
  }

  return null
}
