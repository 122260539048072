import { shallowEqual, useSelector } from 'react-redux'

import { Preloader } from 'mmfintech-portal-commons'
import { DepositResult } from '../DepositResult'
import { CheckoutVoucher } from './Voucher'
import { BlixcardVoucher } from './Blixcard'

import settings from '../../settings.js'
import { withCheckoutContainer } from '../../components'
import {
  CheckoutFlowStep,
  useCheckout,
  useCheckoutPolling,
  useLanguageParam,
  useSessionId,
  useUnloadBeacon
} from 'mmfintech-checkout-commons'
import { PaymentSessionStatusEnum } from 'mmfintech-commons-types'

const WrappedCheckoutBlixcard = withCheckoutContainer(BlixcardVoucher, {
  showLanguageMenu: true,
  showHeader: true
})

const WrappedCheckoutVoucher = withCheckoutContainer(CheckoutVoucher, {
  showLanguageMenu: true,
  showHeader: true
})

const DepositResultWrapped = withCheckoutContainer(DepositResult, {
  showLanguageMenu: true,
  showHeader: true
})

const Checkout = () => {
  useLanguageParam(settings.languages)
  const { sessionId } = useSessionId()

  const { checkoutPay, session } = useSelector(
    ({ common: { selectedLanguage }, checkout: { checkoutPay, session } }) => ({
      checkoutPay,
      session,
      selectedLanguage
    }),
    shallowEqual
  )

  const { sessionStatus, paymentResult, paymentResultType, processingAmount, processingCurrency } = session || {}

  useUnloadBeacon({ sessionId })

  const { startPolling } = useCheckoutPolling({
    sessionId,
    pollingInterval: 5000,
    maxPollCount: 60
  })

  const {
    fields,
    initialized,
    step,
    countryCode,
    setCountryCode,
    paymentMethod,
    setPaymentMethod,
    logEvent,
    handlePay,
    formValues,
    hasBankSelection,
    hasAdditionalFields,
    showFieldsAfterBanks
  } = useCheckout({
    enableLogging: true,
    preselectSingleBank: true,
    preselectSingleMethod: true,
    initialStep: CheckoutFlowStep.SELECT_METHOD,
    preselectMethod: settings.blixcard ? '' : 'INSTANT_BANK_TRANSFER'
  })

  const parseResult = () => {
    try {
      return JSON.parse(paymentResult)
    } catch (error) {
      return paymentResult
    }
  }

  const prepareResponse = () =>
    sessionStatus?.value === PaymentSessionStatusEnum.IN_PROGRESS
      ? { result: parseResult(), resultType: paymentResultType, processingAmount, processingCurrency }
      : checkoutPay

  if (!initialized) {
    return <Preloader />
  }

  if (
    step === CheckoutFlowStep.ADDITIONAL_FIELDS ||
    step === CheckoutFlowStep.SELECT_METHOD ||
    step === CheckoutFlowStep.SELECT_BANK
  ) {
    return settings.blixcard ? (
      <WrappedCheckoutBlixcard
        sessionId={sessionId}
        fields={fields}
        formValues={formValues}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        handlePay={handlePay}
        logEvent={logEvent}
        hasBankSelection={hasBankSelection}
        hasAdditionalFields={hasAdditionalFields}
      />
    ) : (
      <WrappedCheckoutVoucher
        sessionId={sessionId}
        fields={fields}
        formValues={formValues}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        handlePay={handlePay}
        logEvent={logEvent}
        hasBankSelection={hasBankSelection}
        hasAdditionalFields={hasAdditionalFields}
        showFieldsAfterBanks={showFieldsAfterBanks}
      />
    )
  }

  if (step === CheckoutFlowStep.DEPOSIT_RESULT) {
    return (
      <DepositResultWrapped
        response={prepareResponse()}
        logEvent={logEvent}
        session={session}
        sessionId={sessionId}
        startPolling={startPolling}
      />
    )
  }

  return null
}

export default Checkout
