import brandLogo from '../assets/images/cardakina-logo.png'

import { commonTheme } from './commonTheme'
import { DefaultTheme } from 'styled-components'

export const cardakinaTheme: DefaultTheme = {
  ...commonTheme,

  brandLogo,
  brandLogoMaxHeight: '3rem',

  bankSelectItem: {
    selectedBackgroundColor: '#EDF4FF',
    selectedBorderColor: '#7189CA'
  },

  whitelabel: {
    custom: {
      checkbox: {
        color: '#8d9dad',
        iconColor: '#2867ff',
        alignment: 'center'
      },
      radio: {
        borderBottom: '1px solid #f0f0f0',
        iconColor: '#FF9737'
      }
    },
    header: {
      background: '#11476C',
      borderTop: '1.2rem solid #ff9737',
      borderTopColor: '#ff9737',
      color: '#9db8cb',
      amountColor: '#ffffff',
      amountFont: 'Barlow',
      currencyColor: '#ffffff'
    },
    footer: {
      background: '#11476c',
      backgroundHover: '#11476c',
      backgroundSecondary: '#0a466b',
      color: '#ffffff',
      disabledColor: '#808080'
    },
    scrollbar: {
      background: 'rgba(10, 70, 107, 0.25)',
      borderRadius: '7px',
      width: '14px'
    },
    loader: {
      color: '#FF9737'
    },
    maxWidth: '43rem'
  }
}
