import styled from 'styled-components'

export const VoucherTitle = styled.div`
  color: #454a54;
  font-size: 1.4rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 2.4rem;

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`

export const MethodSelectionContainer = styled.div`
  margin-bottom: 2.4rem;

  .custom-radio {
    border-top: ${({ theme }) => theme.whitelabel.custom.radio.borderBottom};
    &:last-of-type {
      border-bottom: ${({ theme }) => theme.whitelabel.custom.radio.borderBottom} !important;
    }
  }
`

export const BanksContainer = styled.div`
  margin-bottom: 2rem;
`

export const BanksMessage = styled.div`
  padding: 3rem;

  color: #828b99;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
