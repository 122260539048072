import styled from 'styled-components'

export const MethodButtonStyled = styled.div`
  width: 100%;
  min-height: 7rem;
  padding: 1.2rem 1.6rem;
  cursor: pointer;

  display: grid;
  grid-template-columns: 2.5rem 1fr 4.5rem;
  grid-gap: 1.5rem;

  border-radius: 1.2rem;
  border: 1px solid #e1e5ea;

  margin-bottom: 1.6rem;
  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: 500px) {
    grid-template-columns: 3rem 1fr 5rem;
    grid-gap: 2rem;
  }

  &.selected {
    background-color: #f2f2f5;
    border-color: transparent;
  }
  &.disabled {
    cursor: default;
  }
`

export const MethodIcon = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

export const MethodContent = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;

  color: #373942;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  .caption {
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: capitalize;
  }

  .description {
  }
`

export const MethodArrow = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
