import { ReactNode, useContext } from 'react'

import cn from 'classnames'

import { ThemeContext } from 'styled-components'
import { CustomRadioWrapper } from './CustomRadio.styled.js'
import { RadioEmptyIcon, RadioSelectedIcon } from '../../icons'

type CustomRadioProps = {
  icon?: ReactNode
  caption: string
  description?: string
  selected: boolean
  thick?: boolean
  onClick: () => void
}

export const CustomRadio = ({ caption, description, icon, selected, thick, onClick, ...rest }: CustomRadioProps) => {
  const themeContext = useContext(ThemeContext)

  return (
    <CustomRadioWrapper onClick={onClick} className={cn('custom-radio', { thick })} {...rest}>
      <span className='check'>
        {selected ? <RadioSelectedIcon color={themeContext.whitelabel.custom.radio.iconColor} /> : <RadioEmptyIcon />}
      </span>
      <span className='text'>
        <span className='caption'>{caption}</span>
        <span>{description}</span>
      </span>
      <span className='icon'>{icon}</span>
    </CustomRadioWrapper>
  )
}
