import React, { useEffect, useState } from 'react'

import cn from 'classnames'
import Select, { ActionMeta, SingleValue } from 'react-select'

import {
  CustomInputContainer,
  CustomInputLabel,
  CustomInputMessage,
  CustomInputStyled,
  CustomInputWrapper,
  CustomTextareaStyled,
  selectInputStyles,
  StyledPhoneInput
} from './CustomInput.styled'

import { useIsFocused } from '../../hooks'
import PhoneInput from 'react-phone-input-2'
import { isValidString } from 'mmfintech-commons'

type LabelValuePair = {
  value?: string
  label?: string
}

type SelectOption = SingleValue<LabelValuePair>

type CustomInputProps = {
  error?: string
  label?: string
  name: string
  type: 'text' | 'password' | 'textarea' | 'select' | 'phone'
  required?: boolean
  value: string
  onChange: (name: string, value: string) => void
  hint?: string
  options?: LabelValuePair[]
  isSearchable?: boolean
  [key: string]: any
}

export const CustomInput: React.FC<CustomInputProps> = props => {
  const {
    error,
    label,
    name,
    type,
    required,
    value,
    onChange,
    hint,
    options,
    isSearchable,
    country,
    onlyCountries,
    disableDropdown,
    countryCodeEditable,
    ...rest
  } = props

  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(null)

  const { focused, handleEnter, handleBlur } = useIsFocused()

  const handleSelectChange = (selectedOption: unknown, _actionMeta?: ActionMeta<unknown>) => {
    setSelectedOption(selectedOption as SelectOption)
    if (
      typeof onChange === 'function' &&
      typeof selectedOption === 'object' &&
      selectedOption.hasOwnProperty('value')
    ) {
      onChange(name, selectedOption['value'])
    }
  }

  useEffect(() => {
    if (value !== selectedOption?.value) {
      setSelectedOption(Array.isArray(options) ? options.find(option => option.value === value) : null)
    }
  }, [value])

  return (
    <CustomInputWrapper
      className={cn({ focused, 'not-empty': value?.length > 0, error: error?.length > 0 })}
      data-test={`text-input-${name}`}>
      <CustomInputContainer>
        {focused || value?.length > 0 ? <CustomInputLabel>{`${required ? '*' : ''}${label}`}</CustomInputLabel> : null}

        {type === 'textarea' ? (
          <CustomTextareaStyled
            {...rest}
            id={name}
            name={name}
            value={value || ''}
            placeholder={focused || value?.length > 0 ? '' : `${required ? '*' : ''}${label}`}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.name, e.target.value)}
            onFocus={handleEnter}
            onBlur={handleBlur}
            required={!!required}
          />
        ) : type === 'select' ? (
          <Select
            id={name}
            name={name}
            placeholder={focused || value?.length > 0 ? '' : `${required ? '*' : ''}${label}`}
            options={options || []}
            value={selectedOption}
            onChange={handleSelectChange}
            onFocus={handleEnter}
            onBlur={handleBlur}
            styles={selectInputStyles}
            menuPosition='absolute'
            menuPlacement='auto'
            components={{ IndicatorSeparator: () => null }}
            isSearchable={isSearchable}
            {...rest}
          />
        ) : type === 'phone' ? (
          <StyledPhoneInput>
            <PhoneInput
              disableSearchIcon
              // autoFormat={autoFormat}
              // enableSearch={enableSearch}
              // disabled={disabled}
              disableDropdown={disableDropdown}
              placeholder={focused || value?.length > 0 ? '' : `${required ? '*' : ''}${label}`}
              value={isValidString(value) ? value : ''}
              onChange={value => onChange && onChange(name, value)}
              onFocus={handleEnter}
              onBlur={handleBlur}
              country={country}
              countryCodeEditable={countryCodeEditable}
              onlyCountries={onlyCountries}
              // excludeCountries={excludeCountries}
              // preferredCountries={preferredCountries}
              specialLabel={null}
              inputProps={{ id: name, name, required, ...rest }}
              containerStyle={{
                background: 'transparent'
              }}
              inputStyle={{
                background: 'transparent',
                border: 'none',
                paddingLeft: '5rem',
                paddingRight: '16px',
                height: '4rem',
                width: '100%'
              }}
              buttonStyle={{
                background: 'transparent',
                border: 'none',
                width: '5rem'
              }}
            />
          </StyledPhoneInput>
        ) : (
          <CustomInputStyled
            {...rest}
            id={name}
            name={name}
            type={type}
            value={value || ''}
            placeholder={focused || value?.length > 0 ? '' : `${required ? '*' : ''}${label}`}
            onChange={e => onChange(e.target.name, e.target.value)}
            onFocus={handleEnter}
            onBlur={handleBlur}
            required={!!required}
            autoComplete='off'
          />
        )}
      </CustomInputContainer>

      <CustomInputMessage>{error?.length ? error : focused && hint?.length ? hint : ' '}</CustomInputMessage>
    </CustomInputWrapper>
  )
}
