import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { BlixcardBanksStep } from './BlixcardBanksStep'
import { BlixcardFieldsStep } from './BlixcardFieldsStep'
import { BlixcardMethodStep } from './BlixcardMethodStep'
import { BlixcardRedeemStep } from './BlixcardRedeemStep'
import { BlixcardOptionsStep } from './BlixcardOptionsStep'
import { ErrorDisplay, Preloader } from 'mmfintech-portal-commons'

import { tr } from 'mmfintech-commons'
import { useVoucherFlow, VoucherStepEnum } from '../../../hooks'
import { actions, useFilteredBanks } from 'mmfintech-checkout-commons'

import { VoucherProps } from '../../../@types'
import { ThunkDispatch } from 'redux-thunk'
import { PaymentOptionResponse, SupportedBank } from 'mmfintech-commons-types'

export const BlixcardVoucher = (props: VoucherProps) => {
  const {
    sessionId,
    fields,
    formValues,
    countryCode,
    setCountryCode,
    paymentMethod,
    setPaymentMethod,
    handlePay,
    logEvent,
    hasBankSelection,
    hasAdditionalFields
  } = props

  const { banks, banksCount, banksError, checkoutError, checkoutOptions, checkoutPayFetching, session } = useSelector(
    ({
      checkout: {
        banks,
        banksCount,
        banksError,
        checkoutCountries,
        checkoutError,
        checkoutOptions,
        checkoutPayFetching,
        session
      }
    }) => ({
      banks,
      banksCount,
      banksError,
      checkoutCountries,
      checkoutError,
      checkoutOptions,
      checkoutPayFetching,
      session
    }),
    shallowEqual
  )

  const history = useHistory()
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const hasPreselectedBank = () => Array.isArray(banks) && !!banks.find(entry => !!entry['preselect'])

  const voucherFlow = useVoucherFlow({
    handlePay,
    hasAdditionalFields,
    hasBankSelection,
    hasPreselectedBank,
    paymentMethod,
    countryCode
  })

  const filteredBanks = useFilteredBanks((bank: SupportedBank) => handleSelectBank(bank ? bank.bankChoiceId : ''), 0)

  const { showCancelButton, skipVoucherOptionSelection } = session || {}

  const handleCancel = () => {
    if (sessionId) {
      void dispatch(actions.checkout.cancelSessionPayment(sessionId))
    }
    logEvent('cancelled_by_payer')
    history.push('/fail')
    return false
  }

  const handleSelectBank = (bankCode: string) => {
    formValues.setValue('bankChoiceId', bankCode)
    logEvent('bank_selected', bankCode)
  }

  const handleCountryChanged = (value: string): void => {
    if (value != countryCode) {
      dispatch(actions.checkout.cleanupBanks)
      filteredBanks.reset()
      voucherFlow.resetMethods()
      setCountryCode(value)
    }
  }

  const setMethodInternal = (method: string): void => {
    const find = Array.isArray(checkoutOptions)
      ? checkoutOptions.find((option: PaymentOptionResponse) => option.paymentMethod === method)
      : null
    setPaymentMethod(find ? method : '')
  }

  useEffect(() => {
    return () => {
      voucherFlow.reset()
    }
  }, [])

  useEffect(() => {
    switch (voucherFlow.option) {
      case 'buy':
        let method = null
        if (paymentMethod === 'BLIXCARD') {
          method = ''
        }
        if (voucherFlow.filteredMethods.length === 1) {
          method = voucherFlow.filteredMethods[0].paymentMethod
        }
        if (paymentMethod !== method && method != null) {
          setMethodInternal(method)
        }
        break

      case 'redeem':
        if (paymentMethod !== 'BLIXCARD') {
          setMethodInternal('BLIXCARD')
        }
        break

      default:
      // nothing
    }
    // eslint-disable-next-line
  }, [voucherFlow.option, countryCode, voucherFlow.filteredMethods])

  useEffect(() => {
    if (banksCount === 1 && formValues.getValue('bankChoiceId') && !checkoutPayFetching) {
      voucherFlow.continueWithPayment()
    }
    // eslint-disable-next-line
  }, [formValues.getValue('bankChoiceId')])

  if (voucherFlow.step === 'none') {
    return <Preloader />
  }

  return (
    <>
      {voucherFlow.step === VoucherStepEnum.OPTIONS ? (
        <BlixcardOptionsStep
          option={voucherFlow.option}
          setOption={voucherFlow.setOption}
          handleNext={voucherFlow.handleNext}
          logEvent={logEvent}
        />
      ) : voucherFlow.step === VoucherStepEnum.METHODS ? (
        <BlixcardMethodStep
          countryCode={countryCode}
          setCountryCode={handleCountryChanged}
          hasCountrySelected={voucherFlow.hasCountrySelected}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          filteredMethods={voucherFlow.filteredMethods}
          handleBack={voucherFlow.hasBack('methods') ? voucherFlow.handleBack : null}
          handleNext={voucherFlow.handleNext}
          skipVoucherOptionSelection={skipVoucherOptionSelection}
        />
      ) : voucherFlow.step === VoucherStepEnum.FIELDS ? (
        <BlixcardFieldsStep
          countryCode={countryCode}
          paymentMethod={paymentMethod}
          fields={fields}
          formValues={formValues}
          handleBack={voucherFlow.hasBack('fields') ? voucherFlow.handleBack : null}
          handleNext={voucherFlow.handleNext}
          hasBankSelection={hasBankSelection}
        />
      ) : voucherFlow.step === VoucherStepEnum.BANKS ? (
        <BlixcardBanksStep
          countryCode={countryCode}
          paymentMethod={paymentMethod}
          filteredBanks={filteredBanks}
          formValues={formValues}
          handleBack={voucherFlow.hasBack('banks') ? voucherFlow.handleBack : null}
          handleNext={voucherFlow.handleNext}
          logEvent={logEvent}
        />
      ) : voucherFlow.step === VoucherStepEnum.REDEEM ? (
        <BlixcardRedeemStep
          paymentMethod={paymentMethod}
          countryCode={countryCode}
          setCountryCode={handleCountryChanged}
          fields={fields}
          formValues={formValues}
          hideBack={voucherFlow.hasOnlyBlixcard}
          handleBack={voucherFlow.handleBack}
          handleNext={voucherFlow.handleNext}
        />
      ) : (
        <ErrorDisplay error={[checkoutError, banksError]} />
      )}

      {showCancelButton ? (
        <div className='buttons-container'>
          <button className='cancel-btn' onClick={handleCancel} data-test='cancel-button'>
            {tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          </button>
        </div>
      ) : null}
    </>
  )
}
