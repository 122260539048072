import styled from 'styled-components'

export const BankButtonStyled = styled.div`
  min-height: 7rem;
  padding: 1rem;

  display: grid;
  grid-gap: 0.8rem;
  grid-template-columns: 4.5rem 1fr 5rem;

  cursor: pointer;
  border-bottom: ${({ theme }) => theme.whitelabel.custom.radio.borderBottom};

  font-size: 1.2rem;
  font-weight: 400;
  line-height: normal;

  &.maintenance {
    cursor: not-allowed;
  }

  &.bank-inner {
    margin-left: 4rem;

    @media (min-width: 500px) {
      margin-left: 6rem;
    }
  }

  &.back {
    grid-template-columns: 4.5rem 1fr;
    grid-gap: 2rem;
    border-color: transparent;
    margin-bottom: 1rem;

    svg {
      transform: rotate(180deg);
    }
  }

  .icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img,
    svg {
      max-height: 5rem;
      max-width: 5rem;
    }
  }

  .text {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;

    .caption {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: normal;
    }

    .warning {
      color: #ee2b2b;
      font-weight: 500;
    }
  }

  border-bottom: 1px solid #e1e5ea;

  &.selected,
  &.preselected {
    border-color: transparent;
  }
  &.selected {
    background-color: #f2f2f5;
    border-radius: 1.2rem;
  }
`
