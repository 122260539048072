import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { VoucherBanksStep } from './VoucherBanksStep'
import { VoucherOptionsStep } from './VoucherOptionsStep'
import { ErrorDisplay, Preloader } from 'mmfintech-portal-commons'

import settings from '../../../settings'
import { tr, useDelayedExecute } from 'mmfintech-commons'
import { actions, useFilteredBanks } from 'mmfintech-checkout-commons'

import { SupportedBank } from 'mmfintech-commons-types'
import { ThunkDispatch } from 'redux-thunk'
import { VoucherOption, VoucherProps } from '../../../@types'
import { VoucherStepEnum } from '../../../hooks'

export const CheckoutVoucher = ({
  sessionId,
  // fields,
  formValues,
  countryCode,
  setCountryCode,
  handlePay,
  // hasBankSelection,
  // hasAdditionalFields,
  // showFieldsAfterBanks,
  logEvent
}: VoucherProps) => {
  const { banks, banksCount, banksError, checkoutError, checkoutPayFetching, session } = useSelector(
    ({ checkout: { banks, banksCount, banksError, banksLoaded, checkoutError, checkoutPayFetching, session } }) => ({
      banks,
      banksCount,
      banksError,
      banksLoaded,
      checkoutError,
      checkoutPayFetching,
      session
    }),
    shallowEqual
  )

  const [step, setStep] = useState<string>(null)
  const [error, setError] = useState(null)
  const [option, setOption] = useState<VoucherOption>('buy')
  const [triggered, setTriggered] = useState(false)
  const [eventLogged, setEventLogged] = useState(false)

  const history = useHistory()
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const filteredBanks = useFilteredBanks((bank: SupportedBank) => handleSelectBank(bank ? bank.bankChoiceId : ''), 0)

  const { showCancelButton } = session || {}

  const handleCancelClick = () => {
    if (sessionId) {
      void dispatch(actions.checkout.cancelSessionPayment(sessionId))
    }
    logEvent('cancelled_by_payer')
    history.push('/fail')
    return false
  }

  const handleSelectOption = (value: VoucherOption): void => {
    setError(null)
    setOption(value)
  }

  const handleContinueFromOptions = () => {
    if (!checkoutError && !checkoutPayFetching) {
      switch (option) {
        case 'buy':
          if (banksCount === 1) {
            handlePay()
          } else {
            setStep(VoucherStepEnum.BANKS)
          }
          break

        case 'redeem':
          if (settings.redirect) {
            window.location.href = settings.redirect
          } else {
            setError(tr('CHECKOUT.VOUCHER.OPTIONS.SERVICE_UNAVAILABLE', 'Service is temporary unavailable.'))
          }
          break

        default:
        // nothing
      }
    }
  }

  const handleSelectBank = (bankCode: string) => {
    formValues.setValue('bankChoiceId', bankCode)
    logEvent('bank_selected', bankCode)
  }

  const handleCountryChanged = (value: string): void => {
    if (value != countryCode) {
      dispatch(actions.checkout.cleanupBanks)
      filteredBanks.reset()
      setCountryCode(value)
    }
  }

  const handleBankToStep1 = () => {
    dispatch(actions.checkout.cleanupPayment())
    setStep(VoucherStepEnum.OPTIONS)
  }

  const handleSubmit = () => {
    if (formValues.getValue('bankChoiceId') && !checkoutPayFetching) {
      handlePay()
    }
  }

  useEffect(() => {
    return () => {
      setStep(null)
      setError(null)
      setOption('buy')
      setTriggered(false)
    }
  }, [])

  useEffect(() => {
    if (step == null) {
      if (Array.isArray(banks)) {
        let nextStep = VoucherStepEnum.OPTIONS
        const find = banks.find(entry => !!entry['preselect'])
        if (find) {
          if (banksCount === 1) {
            const { bankChoiceId } = find
            handleSelectBank(bankChoiceId)
          }
          nextStep = VoucherStepEnum.BANKS
        } else if (banksCount === 1) {
          const { bankChoiceId } = banks[0]
          handleSelectBank(bankChoiceId)
        }
        if (nextStep === VoucherStepEnum.BANKS) {
          logEvent('buy_redeem_page_skipped')
        }
        setStep(nextStep)
      } else {
        setStep(VoucherStepEnum.OPTIONS)
      }
    }
    // eslint-disable-next-line
  }, [banks])

  useEffect(() => {
    if (!triggered && formValues.getValue('bankChoiceId') && !checkoutPayFetching && banksCount === 1) {
      setTriggered(true)
      handleSubmit()
    }
    // eslint-disable-next-line
  }, [formValues.getValue('bankChoiceId')])

  const delayedLog = useDelayedExecute({
    name: 'delayedEventLogging',
    onExecute: () => {
      logEvent('banks_scrolling', '', true)
    }
  })

  const handleScroll = () => {
    delayedLog.execute()
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      logEvent('banks_scrolled_to_bottom', '', true)
    }
  }

  const logBankSearchFocus = () => logEvent('bank_search_focus', null, true)

  const logBankSearchType = () => {
    if (!eventLogged) {
      logEvent('bank_search_type', null, true)
      setEventLogged(true)
    }
  }

  const removeEventListener = () => {
    window.removeEventListener('scroll', handleScroll)
  }

  useEffect(() => {
    if (step === VoucherStepEnum.BANKS) {
      window.addEventListener('scroll', handleScroll)
      return () => {
        removeEventListener()
      }
    } else {
      removeEventListener()
    }
    // eslint-disable-next-line
  }, [step])

  useEffect(() => {
    if (checkoutError) {
      if (step == null) {
        setStep(VoucherStepEnum.OPTIONS)
      }
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [checkoutError])

  if (step == null) {
    return <Preloader />
  }

  return (
    <>
      {step === VoucherStepEnum.OPTIONS ? (
        <VoucherOptionsStep
          error={error}
          option={option}
          setOption={handleSelectOption}
          onContinue={handleContinueFromOptions}
          logEvent={logEvent}
        />
      ) : step === VoucherStepEnum.BANKS ? (
        <VoucherBanksStep
          filteredBanks={filteredBanks}
          countryCode={countryCode}
          setCountryCode={handleCountryChanged}
          formValues={formValues}
          handleBack={handleBankToStep1}
          handleContinue={handlePay}
          logBankSearchFocus={logBankSearchFocus}
          logBankSearchType={logBankSearchType}
        />
      ) : step === VoucherStepEnum.FIELDS ? (
        <>
          {/*

        todo additional fields

        */}
        </>
      ) : (
        <ErrorDisplay error={[checkoutError, banksError]} />
      )}

      {showCancelButton ? (
        <div className='buttons-container'>
          <button className='cancel-btn' onClick={handleCancelClick} data-test='cancel-button'>
            {tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          </button>
        </div>
      ) : null}
    </>
  )
}
