import brandLogo from '../assets/images/tazuki-logo.png'

import { commonTheme } from './commonTheme'
import { DefaultTheme } from 'styled-components'

export const tazukiTheme: DefaultTheme = {
  ...commonTheme,

  brandLogo,
  brandLogoMaxHeight: '3rem',

  bankSelectItem: {
    selectedBackgroundColor: '#EDF4FF',
    selectedBorderColor: '#7189CA'
  },

  whitelabel: {
    custom: {
      checkbox: {
        color: '#454A54',
        iconColor: '#000000',
        alignment: 'center'
      },
      radio: {
        borderBottom: '1px solid #f0f0f0',
        iconColor: '#CA127F'
      }
    },
    header: {
      background: '#021E66',
      borderTop: '1.2rem solid #ca127f',
      borderTopColor: '#ca127f',
      color: '#99a6a7',
      amountColor: '#ffffff',
      amountFont: 'Barlow',
      currencyColor: '#ffffff'
    },
    footer: {
      background: '#021e66',
      backgroundHover: '#021e66',
      backgroundSecondary: '#687b99',
      color: '#ffffff',
      disabledColor: '#ffffff'
    },
    scrollbar: {
      background: 'rgba(22, 51, 97, 0.3)',
      borderRadius: '6px',
      width: '16px'
    },
    loader: {
      color: '#CA127F'
    },

    maxWidth: '43rem'
  }
}
