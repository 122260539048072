import styled from 'styled-components'

export const CheckoutHeader = styled.div`
  grid-area: header;

  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .logo {
    img {
      height: 3rem;
    }
  }
`

export const MessageWrapper = styled.div`
  padding: 5rem 2rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  color: #454a54;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;

  .subtitle {
    color: #7cc670;
    font-size: 120%;
    margin: 1rem 0;

    &.failed {
      color: #e35462;
    }
  }

  p {
    text-align: center;
    margin: 0.5rem 2rem;
  }

  .voucher-details {
    border-radius: 8px;
    width: 100%;
    margin: 2rem 0 0;
    border-top: ${({ theme }) => theme.whitelabel.header.borderTop};
    overflow: hidden;

    .upper {
      background-color: #f8f8fa;
    }

    .value-wrapper {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.5rem 0 1.5rem 4rem;

      line-height: 2.4rem;
      letter-spacing: 0.5px;
      .caption {
        font-size: 1.2rem;
        font-weight: 400;
      }
      .value {
        font-size: 1.6rem;
        font-weight: 500;
      }
    }

    .double-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      background-color: #e8eaef;
    }
  }

  .timer {
    color: #b4bfcb;
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin-top: 1rem;
    margin-bottom: 4rem;
  }
`

export const ProblemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  margin: 3rem auto 2rem;
  text-align: center;

  cursor: pointer;

  border-bottom: 1px solid rgba(69, 74, 84, 1);

  span {
    color: rgba(69, 74, 84, 1);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:hover {
    border-bottom-color: #2f80ed;

    svg > * {
      fill: #2f80ed;
    }
    span {
      color: #2f80ed;
    }
  }
`
