import styled from 'styled-components'

export const ContentWrapperStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;

  margin: 0;
  padding: 2rem 1rem 0;
  min-height: 100vh;
  z-index: 0;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  font-family: ${({ theme }) => theme.fontName}, sans-serif;
  background-color: #f6f7f8;

  @media (max-width: 768px) {
    min-height: 100svh;
  }

  .checkout-inner-container {
    position: relative;
    max-width: ${({ theme }) => theme.whitelabel.maxWidth};
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
  }
`
