import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import settings from '../../../settings'

import { tr } from 'mmfintech-commons'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { CustomCheckbox, CustomRadio, Loader } from '../../../components'
import { MethodSelectionContainer, VoucherTitle } from './CheckoutVoucher.styled'
import { VoucherOption } from '../../../@types'

type VoucherOptionsStepProps = {
  error: string
  option: VoucherOption
  setOption: (value: VoucherOption) => void
  onContinue: () => void
  logEvent: (arg: string) => void
}

export const VoucherOptionsStep: React.FC<VoucherOptionsStepProps> = ({
  error,
  option,
  setOption,
  onContinue,
  logEvent
}) => {
  const { checkoutError, checkoutPayFetching } = useSelector(
    ({ checkout: { checkoutError, checkoutPayFetching } }) => ({
      checkoutError,
      checkoutPayFetching
    }),
    shallowEqual
  )

  const [agree, setAgree] = useState(true)

  const toggleAgree = () => {
    logEvent('terms_checkbox_clicked')
    setAgree(prevState => !prevState)
  }

  const prepareLink = (caption: string) => {
    const list = caption.split('<a>')
    const prefix = list.length > 0 ? list[0] : ''
    const remain = list.length > 1 ? list[1] : ''
    const list2 = remain.split('</a>')
    const inner = list2.length > 0 ? list2[0] : ''
    const suffix = list2.length > 1 ? list2[1] : ''
    return (
      <>
        {prefix}
        <a href={settings.termsUrl} target='_blank' rel='noreferrer' onClick={() => logEvent('terms_link_clicked')}>
          {inner}
        </a>
        {suffix}
      </>
    )
  }

  const setAction = (value: VoucherOption): void => {
    logEvent(`${value}_voucher_clicked`)
    setOption(value)
  }

  return (
    <>
      <VoucherTitle data-test='option-select-page'>
        {tr('CHECKOUT.VOUCHER.OPTIONS.TITLE', 'Select method')}
      </VoucherTitle>

      <ErrorDisplay error={[checkoutError, error]} />

      <MethodSelectionContainer>
        <CustomRadio
          data-test='buy-option'
          caption={tr('CHECKOUT.VOUCHER.OPTIONS.BUY', 'Buy')}
          description={tr(
            'CHECKOUT.VOUCHER.OPTIONS.BUY_DESCRIPTION',
            'You can purchase voucher via bank transfer instantly'
          )}
          selected={option === 'buy'}
          onClick={() => setAction('buy')}
        />
        <CustomRadio
          data-test='redeem-option'
          caption={tr('CHECKOUT.VOUCHER.OPTIONS.REDEEM', 'Redeem')}
          description={tr(
            'CHECKOUT.VOUCHER.OPTIONS.REDEEM_DESCRIPTION',
            'If you already have voucher you can redeem from here'
          )}
          selected={option === 'redeem'}
          onClick={() => setAction('redeem')}
        />
      </MethodSelectionContainer>

      <CustomCheckbox
        data-test='agree-checkbox'
        checked={agree}
        toggle={toggleAgree}
        text={
          settings.termsUrl
            ? prepareLink(tr('CHECKOUT.VOUCHER.OPTIONS.AGREE_LINK', 'I agree to the <a>Terms and Conditions</a>'))
            : tr('CHECKOUT.VOUCHER.OPTIONS.AGREE', 'I agree to the Terms and Conditions')
        }
      />

      {settings.blixcard ? null : (
        <div className='buttons-container mt-2'>
          <button
            className='continue-btn'
            disabled={!agree || checkoutError || checkoutPayFetching}
            onClick={onContinue}>
            {checkoutPayFetching ? <Loader /> : tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
          </button>
        </div>
      )}
    </>
  )
}
