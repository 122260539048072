import { useState } from 'react'

export const useIsFocused = (onFocus?: () => void, onBlur?: () => void) => {
  const [focused, setFocused] = useState(false)

  const handleBlur = () => {
    setFocused(false)
    typeof onBlur === 'function' && onBlur()
  }
  const handleEnter = () => {
    setFocused(true)
    typeof onFocus === 'function' && onFocus()
  }

  return { focused, handleBlur, handleEnter }
}
