import styled from 'styled-components'

export const CustomSearchStyled = styled.div`
  position: relative;
  width: 100%;
  max-height: 7rem;

  display: flex;
  flex-flow: column nowrap;
`

export const CustomSearchContainer = styled.div`
  border-bottom: 2px solid #e6e6e8;

  display: -webkit-grid;
  display: grid;
  -webkit-grid-gap: 1rem;
  grid-gap: 1rem;
  -webkit-grid-template-columns: 4rem 1fr 4rem;
  grid-template-columns: 4rem 1fr 4rem;

  .focused > & {
    border-bottom-color: #55419b;
  }
  .error > & {
    border-bottom-color: #e35462;
  }
`

export const CenterVertically = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

export const CustomSearchIcon = styled.div`
  width: 4rem;
  height: 4rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.pointer {
    cursor: pointer;
  }
`

export const CustomSearchContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 5.6rem;
`

export const CustomSearchLabel = styled.label`
  color: #55419b;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;

  .error & {
    color: #e35462;
  }
`

export const CustomSearchInput = styled.input`
  height: 2.4rem;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;

  color: #1d1e21;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;

  &::placeholder,
  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: #818389;
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
  }
`

export const CustomSearchMessage = styled.div`
  width: 100%;
  padding: 0.4rem 1.6rem;

  color: #818389;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  .error > & {
    color: #e35462;
  }
`
