import styled from 'styled-components'

export const CustomInputWrapper = styled.div`
  position: relative;
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
`

export const CustomInputContainer = styled.div`
  position: relative;
  min-height: 5.6rem;
  padding: 0.8rem 1.6rem;
  margin-top: 0.8rem;

  border: 2px solid #818389;
  border-radius: 1.2rem;

  .not-empty & {
    border-color: #1d1e21;
  }
  .focused & {
    border-color: #55419b;
  }
  .error & {
    border-color: #e35462;
  }
`

export const CustomInputLabel = styled.label`
  position: absolute;
  padding: 0 0.4rem;
  left: 1.6rem;
  top: -0.8rem;

  color: #1d1e21;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;

  background: #ffffff;

  .focused & {
    color: #55419b;
  }
  .error & {
    color: #e35462;
  }
`

export const CustomInputStyled = styled.input`
  height: 4rem;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
  background: transparent;

  color: #373942;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;

  &::placeholder {
    color: #818389;
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
  }
  &:-ms-input-placeholder {
    color: #818389;
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
  }
  &::-webkit-input-placeholder {
    color: #818389;
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
  }
`

export const CustomTextareaStyled = styled.textarea`
  width: 100%;
  border: none;
  outline: none;
  background: transparent;

  color: #373942;
  font-family: inherit;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;

  &::placeholder {
    color: #818389;
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
  }
  &:-ms-input-placeholder {
    color: #818389;
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
  }
  &::-webkit-input-placeholder {
    color: #818389;
    font-family: ${({ theme }) => theme.fontName};
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.4rem;
  }
`

export const CustomInputMessage = styled.div`
  padding: 0.4rem 1.6rem;

  color: #818389;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6rem;

  .error & {
    color: #e35462;
  }
`

export const selectInputStyles = {
  container: (provided: any) => ({
    ...provided,
    margin: '0',
    width: '100%'
  }),
  control: (provided: any) => ({
    ...provided,
    //   background: menuIsOpen ? themeContext.input.dropDown.openedBackground : 'transparent',
    border: 'none',
    //   borderRadius: menuIsOpen
    //     ? themeContext.input.dropDown.openedBorderRadius
    //     : themeContext.input.dropDown.controlBorderRadius,
    color: '#1d1e21',
    fontSize: '1.6rem',
    fontWeight: '400',
    lineHeight: '2.4rem',
    //   cursor: 'pointer',
    //   minHeight: themeContext.input.settings.height,
    padding: 0,
    transition: 'none',
    boxShadow: 'none',
    outline: 'none'
    //   ':hover': {
    //     border:
    //       (menuIsOpen ? themeContext.input.dropDown.openedBorder : themeContext.input.dropDown.controlBorder) +
    //       ' !important'
    //   }
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'inherit'
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    paddingRight: 0,
    color: '#818389'
  }),
  // placeholder: (provided: any) => ({
  //   ...provided,
  //   color: themeContext.input.placeholder.textColor,
  //   fontSize: themeContext.input.placeholder.fontSize,
  //   fontStyle: 'normal',
  //   fontWeight: themeContext.input.placeholder.fontWeight,
  //   lineHeight: themeContext.input.placeholder.lineHeight
  // }),
  menu: (provided: any) => ({
    ...provided,
    //   background: themeContext.input.dropDown.menuBackground,
    border: 'none',
    outline: 'none',
    boxShadow: '4px 4px 8px 0px rgba(149, 149, 149, 0.25)',
    borderRadius: '1rem',
    //   margin: themeContext.input.dropDown.menuMargin,
    //   padding: themeContext.input.dropDown.menuPadding,
    //   ':hover': {
    //     border: 'none !important'
    //   }
    top: '4.2rem',
    left: '-1.9rem',
    width: 'calc(100% + 3.8rem)'
  }),
  option: (provided: any, { isFocused }: { isFocused?: boolean }) => ({
    ...provided,
    color: '#1d1e21',
    background: isFocused ? '#f2f2f5' : 'transparent',
    //   borderRadius:
    //     value === 'new' ? themeContext.input.dropDown.buttonBorderRadius : themeContext.input.dropDown.optionBorderRadius,
    //   cursor: 'pointer',
    fontFamily: 'inherit',
    fontSize: '1.6rem',
    fontWeight: '400',
    lineHeight: '2.4rem',
    padding: '0.8rem 1.2rem'
  })
  // value: {}
}

export const StyledPhoneInput = styled.div`
  width: 100%;

  .react-tel-input {
    background: transparent;
    font-family: inherit;

    .form-control {
      height: 4rem;
      width: 100%;
      padding: 0;
      border: none;
      outline: none;
      background: transparent;

      color: #373942;
      font-family: inherit;
      font-size: ${({ theme }) => theme.input.container.fontSize};
      font-style: normal;
      font-weight: ${({ theme }) => theme.input.container.fontWeight};
      line-height: ${({ theme }) => theme.input.container.lineHeight};

      &::-webkit-input-placeholder {
        color: #818389;
        font-family: ${({ theme }) => theme.fontName};
        font-size: ${({ theme }) => theme.input.placeholder.fontSize};
        font-style: normal;
        font-weight: ${({ theme }) => theme.input.placeholder.fontWeight};
        line-height: ${({ theme }) => theme.input.placeholder.lineHeight};
      }
      &:-ms-input-placeholder {
        color: #818389;
        font-family: ${({ theme }) => theme.fontName};
        font-size: ${({ theme }) => theme.input.placeholder.fontSize};
        font-style: normal;
        font-weight: ${({ theme }) => theme.input.placeholder.fontWeight};
        line-height: ${({ theme }) => theme.input.placeholder.lineHeight};
      }
      &::placeholder {
        color: #818389;
        font-family: ${({ theme }) => theme.fontName};
        font-size: ${({ theme }) => theme.input.placeholder.fontSize};
        font-style: normal;
        font-weight: ${({ theme }) => theme.input.placeholder.fontWeight};
        line-height: ${({ theme }) => theme.input.placeholder.lineHeight};
      }

      // :-webkit-autofill,
      // :-webkit-autofill:hover,
      // :-webkit-autofill:focus {
      //   box-shadow: 0 0 3rem 3rem {({ theme }) => theme.input.settings.autofillBackground} inset !important;
      //   -webkit-box-shadow: 0 0 0 3rem {({ theme }) => theme.input.settings.autofillBackground} inset !important;
      //   -webkit-text-fill-color: #373942 !important;
      //   caret-color: #373942 !important;
      //   background: {({ theme }) => theme.input.settings.autofillBackground} !important;
      //   outline: none !important;
      //   border: none !important;
      // }
    }

    .flag-dropdown {
      background: transparent;
      border: none;
      border-radius: 0;

      .selected-flag {
        :focus,
        :hover {
          background: transparent;
        }
        &.open {
          background: transparent;
        }
        .arrow {
          border-top: 4px solid ${({ theme }) => theme.input.settings.dropDownArrowColor};
          &.up {
            border-top: none;
            border-bottom: 4px solid ${({ theme }) => theme.input.settings.dropDownArrowColor};
          }
        }
      }
    }

    .country-list {
      color: ${({ theme }) => theme.input.dropDown.optionColor};
      font-family: inherit;
      font-size: ${({ theme }) => theme.input.dropDown.optionFontSize};
      font-weight: ${({ theme }) => theme.input.dropDown.optionFontWeight};
      line-height: ${({ theme }) => theme.input.dropDown.optionLineHeight};

      .country {
        &.highlight {
          //background: #dedede;
        }
        :hover {
          color: ${({ theme }) => theme.input.dropDown.focusedOptionColor};
          background: ${({ theme }) => theme.input.dropDown.focusedOptionBackground};
          //.dial-code {
          //  color: #afafaf;
          //}
        }
      }
    }
  }
`
