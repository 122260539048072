import { CustomCheckboxWrapper } from './CustomCheckbox.styled.js'
// import { CheckboxCheckedIcon, CheckboxEmptyIcon } from '../../icons'

export const CustomCheckbox = ({ checked, toggle, text, ...rest }) => {
  return (
    <CustomCheckboxWrapper>
      <div className='checkbox-container' onClick={toggle} {...rest}>
        <div className={`checkbox ${checked ? 'checked' : ''}`}>
          {checked && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='10'
              height='8'
              viewBox='0 0 10 8'
              fill='none'
              className='checkmark'>
              <path
                d='M1 3.13333L4 6.33333L9 1'
                stroke='#454A54'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          )}
        </div>
      </div>
      <span className='text'>{text}</span>
    </CustomCheckboxWrapper>
  )
}
