import { Button } from 'mmfintech-portal-commons'
import { FailureIcon } from '../../icons'
import { AlertContent, AlertWrapper } from './ErrorFallback.styled'

export const ErrorFallback = ({ resetErrorBoundary }) => (
  <AlertWrapper>
    <AlertContent>
      <FailureIcon />

      <p>An unexpected error occurred</p>

      {resetErrorBoundary ? (
        <Button color='alternative' onClick={resetErrorBoundary} text='Go back to the home page' />
      ) : null}
    </AlertContent>
  </AlertWrapper>
)
