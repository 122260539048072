import styled from 'styled-components'

export const MenuWrapper = styled.div`
  color: ${({ theme }) => theme.languageMenu.color};
  font-size: ${({ theme }) => theme.languageMenu.fontSize};
  font-style: normal;
  font-weight: ${({ theme }) => theme.languageMenu.fontWeight};
  line-height: normal;

  position: relative;
`

export const MenuContainer = styled.div`
  cursor: pointer;
  height: ${({ theme }) => theme.languageMenu.container.height};

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 5px;

  border: ${({ theme }) => theme.languageMenu.container.border};
  border-radius: ${({ theme }) => theme.languageMenu.container.borderRadius};
  padding: ${({ theme }) => theme.languageMenu.container.padding};

  .selected {
    width: 100%;
  }
`

export const MenuDropDown = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.languageMenu.dropDown.top};
  right: 0;

  width: max-content;
  padding: 0;
  border-radius: ${({ theme }) => theme.languageMenu.dropDown.borderRadius};

  background-color: ${({ theme }) => theme.languageMenu.dropDown.backgroundColor};
  box-shadow: ${({ theme }) => theme.languageMenu.dropDown.boxShadow};

  z-index: 999;

  display: none;
  &.opened {
    display: inline-block;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      color: ${({ theme }) => theme.languageMenu.dropDown.color};
      height: ${({ theme }) => theme.languageMenu.dropDown.itemHeight};
      padding: ${({ theme }) => theme.languageMenu.dropDown.padding};

      :hover {
        color: ${({ theme }) => theme.languageMenu.dropDown.hoverColor};
        background-color: ${({ theme }) => theme.languageMenu.dropDown.hoverBackgroundColor};

        &:first-of-type {
          border-radius: ${({ theme }) => theme.languageMenu.dropDown.borderRadius}
            ${({ theme }) => theme.languageMenu.dropDown.borderRadius} 0 0;
        }
        &:last-of-type {
          border-radius: 0 0 ${({ theme }) => theme.languageMenu.dropDown.borderRadius}
            ${({ theme }) => theme.languageMenu.dropDown.borderRadius};
        }
      }
    }
  }
`
