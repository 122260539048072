import React, { useContext } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import cn from 'classnames'

import { LanguageMenu } from '../LanguageMenu'
import { ThemeContext } from 'styled-components'
import { ThunkDispatch } from 'redux-thunk'
import { BlixcardAmount, BlixcardHeader, BlixcardHeaderBack } from './Blixcard.styled'
import { CheckoutVoucherBody, CheckoutVoucherHeader, CheckoutVoucherUpper, ProblemWrapper } from '../Commons'

import settings from '../../settings'

import { GlobalContext, tr } from 'mmfintech-commons'
import { actions, useSessionId } from 'mmfintech-checkout-commons'

import BlixcardLogo from '../../assets/images/blixcard-white.png'
import { PenIcon } from '../../icons/PenIcon'

interface WithCheckoutContainerOptions {
  showLanguageMenu?: boolean
  showHeader?: boolean
}

export const withCheckoutContainer = <P extends object>(
  Component: React.FC<P>,
  options: WithCheckoutContainerOptions = {
    showLanguageMenu: false,
    showHeader: false
  }
): React.FC<P> => {
  return (props: P) => {
    const { modalShow } = useContext(GlobalContext)
    const { showLanguageMenu, showHeader } = options
    const { session } = useSelector(
      ({ checkout: { session } }) => ({
        session
      }),
      shallowEqual
    )

    const { amount, currency, showAmount, showCurrency } = session || {}

    const { sessionId } = useSessionId()
    const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()
    const themeContext = useContext(ThemeContext)

    const handleLanguageChanged = (code: string) => {
      void dispatch(actions.common.storeSelectedLanguage(sessionId, code))
    }

    const displayAmount = showAmount || amount
    const displayCurrency = showCurrency || currency

    if (settings.blixcard) {
      return (
        <div className='checkout-inner-container'>
          <BlixcardHeaderBack />
          <BlixcardHeader>
            <img src={BlixcardLogo} alt='' />
            {showLanguageMenu && <LanguageMenu onChange={handleLanguageChanged} />}
          </BlixcardHeader>

          {showHeader && displayAmount > 0 && (
            <BlixcardAmount>
              <div>
                <span className='amount' data-test='amount'>
                  {displayAmount}
                </span>
                <span className='currency' data-test='currency'>
                  {displayCurrency}
                </span>
              </div>
            </BlixcardAmount>
          )}

          <CheckoutVoucherBody className={cn({ blixcard: showHeader && displayAmount > 0 })}>
            <Component {...(props as P)} />
          </CheckoutVoucherBody>

          {typeof modalShow === 'function' && sessionId ? (
            <ProblemWrapper onClick={() => modalShow(null)} data-test='report-a-problem-button'>
              <PenIcon />
              <span>{tr('CHECKOUT.PROBLEM.TITLE', 'Report a Problem')}</span>
            </ProblemWrapper>
          ) : null}
        </div>
      )
    }

    return (
      <div className='checkout-inner-container'>
        <CheckoutVoucherUpper>
          {themeContext.hasOwnProperty('brandLogoText') ? (
            <b className='logo'>{themeContext.brandLogoText}</b>
          ) : (
            <img src={themeContext.brandLogo} alt='' />
          )}
          {showLanguageMenu && <LanguageMenu onChange={handleLanguageChanged} />}
        </CheckoutVoucherUpper>
        <div>
          {showHeader && (
            <CheckoutVoucherHeader>
              <span className='amount' data-test='amount'>
                {displayAmount}
              </span>
              <span className='currency' data-test='currency'>
                {displayCurrency}
              </span>
            </CheckoutVoucherHeader>
          )}
          <CheckoutVoucherBody>
            <Component {...(props as P)} />
          </CheckoutVoucherBody>
        </div>
        {typeof modalShow === 'function' && sessionId ? (
          <ProblemWrapper onClick={() => modalShow(null)} data-test='report-a-problem-button'>
            <PenIcon />
            <span>{tr('CHECKOUT.PROBLEM.TITLE', 'Report a Problem')}</span>
          </ProblemWrapper>
        ) : null}
      </div>
    )
  }
}
