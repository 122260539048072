import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { CustomInput } from '../../../components/CustomInput'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { VoucherTitle } from './Blixcard.styled'
import { BackButton, Loader } from '../../../components'

import { CheckoutMethodField } from 'mmfintech-commons-types'

import { getCountryAlpha2, tr } from 'mmfintech-commons'

type BlixcardFieldsStepProps = {
  countryCode: string
  paymentMethod: string
  fields: CheckoutMethodField[]
  formValues: any
  handleBack: () => void
  handleNext: () => void
  hasBankSelection: () => boolean
}

export const BlixcardFieldsStep: React.FC<BlixcardFieldsStepProps> = ({
  countryCode,
  paymentMethod,
  fields,
  formValues,
  handleBack,
  handleNext,
  hasBankSelection
}) => {
  const { checkoutError, checkoutPayFetching } = useSelector(
    ({ checkout: { checkoutError, checkoutPayFetching } }) => ({
      checkoutError,
      checkoutPayFetching
    }),
    shallowEqual
  )

  const handleSubmit = () => {
    if (hasBankSelection()) {
      formValues.setRequired('bankChoiceId', false)
    }

    if (formValues.areValid()) {
      handleNext()
    }
  }

  return (
    <>
      {typeof handleBack === 'function' ? (
        <div className='mb-2'>
          <BackButton onClick={handleBack} />
        </div>
      ) : null}

      <VoucherTitle data-test={`pay-with-${paymentMethod}-banks`}>
        {tr('CHECKOUT.VOUCHER.FIELDS.TITLE', 'Pay with {{METHOD}}', {
          METHOD: tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)
        })}
      </VoucherTitle>

      <ErrorDisplay error={[checkoutError]} />

      <div>
        {Array.isArray(fields) && fields.length
          ? fields.map((item, index) => {
              const { name, type, defaultLabel, translationKey, required, sensitive, options } = item

              if (name === 'bankChoiceId') {
                return null
              }

              if (type.toLowerCase() === 'select') {
                const extractedOptions =
                  typeof options === 'object' ? Object.entries(options).map(([value, label]) => ({ value, label })) : []
                return (
                  <CustomInput
                    key={index}
                    type='select'
                    data-test={`${name}-select`}
                    options={extractedOptions}
                    label={tr(translationKey, defaultLabel)}
                    {...formValues.registerInput(name)}
                    required={required}
                    hideErrorLine
                    menuPlacement='top'
                  />
                )
              }

              if (name === 'phone') {
                return (
                  <CustomInput
                    type='phone'
                    data-test={`${name}-input`}
                    label={tr(translationKey, defaultLabel)}
                    {...formValues.registerInput(name)}
                    required={required}
                    hideErrorLine
                    autoComplete='off'
                    {...(paymentMethod === 'INTERAC'
                      ? {
                          country: 'ca',
                          onlyCountries: ['ca'],
                          countryCodeEditable: false,
                          disableDropdown: true
                        }
                      : {
                          country: getCountryAlpha2(countryCode)?.toLowerCase()
                        })}
                  />
                )
              }

              if (type.toLowerCase() === 'date') {
                return (
                  <CustomInput
                    key={index}
                    type='date'
                    data-test={`${name}-input`}
                    label={tr(translationKey, defaultLabel)}
                    {...formValues.registerInput(name)}
                    required={required}
                    hideErrorLine
                  />
                )
              }

              return (
                <CustomInput
                  key={index}
                  type={sensitive ? 'password' : 'text'}
                  data-test={`${name}-input`}
                  label={tr(translationKey, defaultLabel)}
                  {...formValues.registerInput(name)}
                  required={required}
                />
              )
            })
          : null}
      </div>

      <div className='buttons-container mt-2'>
        <button disabled={checkoutPayFetching} className='continue-btn' onClick={handleSubmit}>
          {checkoutPayFetching ? <Loader /> : tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
        </button>
      </div>
    </>
  )
}
