export const FailureIcon = () => (
  <svg width='63' height='63' viewBox='0 0 63 63' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='31.4995' cy='31.5' r='31.5' transform='rotate(180 31.4995 31.5)' fill='url(#paint0_linear_555_2215)' />
    <circle cx='31.4997' cy='31.5002' r='21.6818' fill='#E35462' />
    <path d='M23.999 39.0002L38.9993 24' stroke='white' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M23.9988 24L38.999 39.0002' stroke='white' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
    <defs>
      <linearGradient
        id='paint0_linear_555_2215'
        x1='31.4995'
        y1='1.90735e-06'
        x2='31.9086'
        y2='54.8182'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#E35462' />
        <stop offset='1' stopColor='#E3707C' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>
)
