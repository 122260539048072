import styled from 'styled-components'

export const CheckoutVoucherUpper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 2rem;

  width: 100%;

  img {
    height: ${({ theme }) => theme.brandLogoMaxHeight};
  }

  @media (max-width: 480px) {
    margin-right: 4rem;
  }

  b.logo {
    font-size: 2rem;
    color: #a52a2a;
  }
`

export const CheckoutVoucherWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  padding: 0 1rem;

  @media (max-width: 480px) {
    width: 100vw;
  }
`

export const CheckoutVoucherHeader = styled.div`
  background: ${({ theme }) => theme.whitelabel.header.background};
  border-top: ${({ theme }) => theme.whitelabel.header.borderTop};
  border-radius: 12px 12px 0 0;
  width: 100%;
  padding: 2.4rem 2.6rem;

  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  gap: 8px;

  color: ${({ theme }) => theme.whitelabel.header.color};
  font-family: ${({ theme }) => theme.whitelabel.header.amountFont}, 'Barlow', sans-serif;
  font-size: 1.4rem;

  @media (max-width: 480px) {
    font-size: 1.2rem;
    padding: 1.5rem 2rem;
  }

  @media (max-width: 168px) {
    flex-flow: column-reverse nowrap;
  }

  .amount {
    color: ${({ theme }) => theme.whitelabel.header.amountColor};
    font-size: 3.6rem;
    font-weight: 400;
    line-height: 100%;

    @media (max-width: 480px) {
      font-size: 2.4rem;
    }
  }
  .currency {
    color: ${({ theme }) => theme.whitelabel.header.currencyColor};
    line-height: 100%;
    margin-top: 4px;
    @media (max-width: 480px) {
      margin-top: 3px;
    }
  }
`

export const CheckoutVoucherBody = styled.div`
  background: #ffffff;
  padding: 3rem 2.5rem 2rem 2.5rem;
  box-shadow: 4px 4px 15px 0 rgba(142, 142, 142, 0.2);

  border-radius: 0 0 1.2rem 1.2rem;

  &.blixcard {
    padding-top: 6.5rem;
  }

  .err-msg-wrapper {
    padding: 0 0 2.4rem;
    justify-content: center;
  }

  .bank-search {
    .input-wrapper {
      margin: 0;
      div {
        border: none;
        padding: 0;
      }
    }
  }

  .scrollbar1::-webkit-scrollbar {
    width: ${({ theme }) => theme.whitelabel.scrollbar.width};
  }
  .scrollbar1::-webkit-scrollbar-track {
    border-radius: ${({ theme }) => theme.whitelabel.scrollbar.borderRadius};
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
  }
  .scrollbar1::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.whitelabel.scrollbar.borderRadius};
    background-clip: content-box;
    background-color: ${({ theme }) => theme.whitelabel.scrollbar.background};
  }

  .link-container {
    text-align: center;
    padding: 1.2rem 0;
    font-size: 1.6rem;
    font-weight: 500;

    a {
      color: #3a2585;
      text-decoration: none;
      border-bottom: 1px solid #3a2585;
    }
  }

  .buttons-container {
    .continue-btn,
    .cancel-btn {
      width: 100%;
      height: 5rem;

      border: none;
      cursor: pointer;

      font-size: 1.8rem;
      font-weight: 500;

      &:disabled {
        cursor: not-allowed;
      }
    }

    .continue-btn {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: ${({ theme }) => theme.whitelabel.footer.background};
      border-radius: 1.2rem;
      color: ${({ theme }) => theme.whitelabel.footer.color};

      &:hover {
        background-color: ${({ theme }) => theme.whitelabel.footer.backgroundHover};
      }

      &:disabled {
        background-color: #b4bfcb;
      }
    }

    .cancel-btn {
      background-color: transparent;
      color: #818389;

      margin-top: 1rem;

      &:hover {
        color: #021e66;
      }

      &.disabled {
        color: #e1e5ea;
      }
    }
  }
`

export const CheckoutVoucherLoading = styled.div`
  padding: 2rem 0;
  min-height: 5rem;
`
