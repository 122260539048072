import React from 'react'
import cn from 'classnames'

import {
  CenterVertically,
  CustomSearchContainer,
  CustomSearchContent,
  CustomSearchIcon,
  CustomSearchInput,
  CustomSearchLabel,
  CustomSearchMessage,
  CustomSearchStyled
} from './CustomSearch.styled'

import { useIsFocused } from '../../hooks'
import { ResetIcon, SearchIcon } from '../../icons'
import { WarningIcon } from '../../icons/WarningIcon'

type CustomSearchProps = {
  error?: string
  label?: string
  name: string
  hint?: string
  value?: string
  onChange: (name: string, value: string) => void
  onFocus?: () => void
  [key: string]: any
}

export const CustomSearch: React.FC<CustomSearchProps> = props => {
  const { error, label, name, hint, value, onChange, onFocus, ...rest } = props

  const { focused, handleEnter, handleBlur } = useIsFocused(onFocus)

  const handleReset = () => onChange(name, '')

  return (
    <CustomSearchStyled className={cn({ focused, error: error?.length > 0 })} data-test={`text-input-${name}`}>
      <CustomSearchContainer>
        <CenterVertically>
          <CustomSearchIcon>
            <SearchIcon color='#3c464f' />
          </CustomSearchIcon>
        </CenterVertically>

        <CustomSearchContent>
          {focused || value?.length ? <CustomSearchLabel>{label}</CustomSearchLabel> : null}
          <CustomSearchInput
            id={name}
            name={name}
            type='text'
            value={value || ''}
            placeholder={focused || value?.length ? '' : label}
            onChange={e => onChange(e.target.name, e.target.value)}
            onFocus={handleEnter}
            onBlur={handleBlur}
            {...rest}
          />
        </CustomSearchContent>

        <CenterVertically>
          {error?.length > 0 ? (
            <CustomSearchIcon>
              <WarningIcon />
            </CustomSearchIcon>
          ) : value?.length > 0 ? (
            <CustomSearchIcon className='pointer' onClick={handleReset}>
              <ResetIcon />
            </CustomSearchIcon>
          ) : null}
        </CenterVertically>
      </CustomSearchContainer>

      <CustomSearchMessage>{error?.length > 0 ? error : focused || value?.length > 0 ? hint : ''}</CustomSearchMessage>
    </CustomSearchStyled>
  )
}
