import { toast } from 'react-hot-toast'
import { QRCodeSVG } from 'qrcode.react'

import { Button } from 'mmfintech-portal-commons'
import { PreviewPixWrapper } from './PreviewPix.styled'

import { copyTextToClipboard, tr } from 'mmfintech-commons'

import PixLogo from '../../../assets/icons/pix-logo.svg?react'

export const PreviewPix = ({ value }) => (
  <PreviewPixWrapper>
    <p className='text-center'>
      <PixLogo />
    </p>
    <p className='text-center'>
      {tr(
        'FRONTEND.DEPOSIT.RESULT.QR_CODE',
        'Open the app with your registered PIX key, choose Pay with Pix and scan the QR Code or copy and paste the code.'
      )}
    </p>
    <p className='text-center code'>
      <QRCodeSVG value={value} size={256} />
    </p>
    <p className='text-center'>
      <Button
        type='button'
        text={tr('FRONTEND.DEPOSIT.RESULT.COPY_CODE', 'Copy code')}
        onClick={() => {
          copyTextToClipboard(value, () => {
            toast.remove()
            toast.success(tr('FRONTEND.DEPOSIT.RESULT.QR_CODE_COPIED', 'Code is copied to clipboard'))
          })
        }}
      />
    </p>
  </PreviewPixWrapper>
)
