import styled from 'styled-components'

export const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 8rem;
`

export const AlertContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  max-width: 50rem;
  width: 100%;
  border-radius: 1rem;
  padding: 3rem;

  text-align: center;
  font-size: 16px;

  img {
    width: 66px;
    height: 38px;
  }

  .button {
    width: 100%;
    max-width: 30rem;
  }
`
