import { FrameWrapper } from './PreviewFrame.styled'

export const PreviewFrame = ({ result }) => (
  <FrameWrapper>
    <iframe
      src={result}
      style={{ minHeight: '75vh', width: '100%' }}
      title='Redirect'
      id='1c6110c5-1dc1-478d-8237-a9dac0daedc7'
    />
  </FrameWrapper>
)
