import styled from 'styled-components'

export const CustomCheckboxWrapper = styled.div`
  padding-bottom: 1rem;

  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: ${({ theme }) => theme.whitelabel.custom.checkbox.alignment};
  gap: 8px;

  color: ${({ theme }) => theme.whitelabel.custom.checkbox.color};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: normal;

  .checkbox-container {
    cursor: pointer;
    display: inline-block;
    width: 2rem;
    height: 2rem;
  }

  .checkbox {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid #e1e5ea;
    position: relative;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: none;
    stroke: #454a54;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    opacity: 0;
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: checkmark-animation 0.3s forwards 0.1s;
  }

  @keyframes checkmark-animation {
    from {
      stroke-dashoffset: 100;
    }
    to {
      opacity: 1;
      stroke-dashoffset: 0;
    }
  }
`
