import brandLogo from '../assets/images/blixcard-logo.png'

import { commonTheme } from './commonTheme'
import { DefaultTheme } from 'styled-components'

export const blixcardTheme: DefaultTheme = {
  ...commonTheme,

  brandLogo,
  brandLogoMaxHeight: '2.6rem',

  fontName: 'Roboto',

  bankSelectItem: {
    selectedBackgroundColor: '#F0F0FF',
    selectedBorderColor: '#7E72C6'
  },

  languageMenu: {
    color: '#ffffff',
    fontSize: '1.2rem',
    fontWeight: '400',

    container: {
      height: '2rem',
      border: 'none',
      borderRadius: '0',
      padding: '0'
    },

    dropDown: {
      backgroundColor: '#ffffff',
      borderRadius: '4px',
      boxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)',
      top: '2.5rem',
      color: '#55419b',
      padding: '0 2rem',
      itemHeight: '3.5rem',
      hoverColor: '#454a54',
      hoverBackgroundColor: '#e7ebee'
    }
  },

  whitelabel: {
    maxWidth: '43rem',

    custom: {
      checkbox: {
        color: '#454A54',
        iconColor: '#000000',
        alignment: 'flex-start'
      },
      radio: {
        borderBottom: '1px solid #E1E5EA',
        iconColor: '#F6D34C'
      }
    },

    header: {
      background: '#55419B',
      borderTop: '1.2rem solid #f6d34c',
      borderTopColor: '#f6d34c',
      color: '#99a6a7',
      amountColor: '#373942',
      amountFont: 'Roboto',
      currencyColor: '#373942'
    },

    footer: {
      background: '#55419b',
      backgroundHover: '#3e3073',
      backgroundSecondary: '#687b99',
      color: '#ffffff',
      disabledColor: '#ffffff'
    },

    scrollbar: {
      background: 'rgba(22, 51, 97, 0.3)',
      borderRadius: '6px',
      width: '16px'
    },

    loader: {
      color: '#F6D34C'
    }
  },

  button: {
    loadingType: 'ThreeDots',
    primary: {
      height: '5rem',
      background: '#55419b',
      border: 'none',
      borderRadius: '1rem',
      boxShadow: 'none',
      color: '#ffffff',
      fontSize: '1.6rem',
      fontWeight: '500',
      iconColor: '#ffffff',
      iconSpacing: '1.4rem',
      padding: '0 1rem',

      hoverBackground: '#3e3073',
      hoverBorder: 'none',
      hoverBoxShadow: 'none',
      hoverColor: '#ffffff',

      disabledColor: '#ffffff',
      disabledBackground: 'rgba(30, 99, 255, 0.3)',
      disabledBorder: 'none',
      disabledBoxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)'
    },
    secondary: {
      height: '5rem',
      background: '#ffffff',
      border: '1px solid #ffffff',
      borderRadius: '1rem',
      boxShadow: 'none',
      color: 'rgba(30, 99, 255, 0.75)',
      fontSize: '1.6rem',
      fontWeight: '500',
      iconColor: 'rgba(30, 99, 255, 0.75)',
      iconSpacing: '1.4rem',
      padding: '0 1rem',

      hoverBackground: '#ffffff',
      hoverBorder: '1px solid #dfe8ec',
      hoverBoxShadow: 'none',
      hoverColor: '#1e63ff',

      disabledColor: '#ffffff',
      disabledBackground: '#d5dbe2',
      disabledBorder: '1px solid #d5dbe2',
      disabledBoxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)'
    },
    alternative: {
      height: '5rem',
      background: '#ffce32',
      border: 'none',
      borderRadius: '1rem',
      boxShadow: 'none',
      color: '#ffffff',
      fontSize: '1.6rem',
      fontWeight: '500',
      iconColor: '#ffffff',
      iconSpacing: '1.4rem',
      padding: '0 1rem',

      hoverBackground: '#ffd44c',
      hoverBorder: 'none',
      hoverBoxShadow: 'none',
      hoverColor: '#ffffff',

      disabledColor: '#ffffff',
      disabledBackground: '#d5dbe2',
      disabledBorder: 'none',
      disabledBoxShadow: '0 5px 30px rgba(172, 182, 191, 0.25)'
    },
    'round-primary': {
      height: '4rem',
      background: '#ffffff',
      border: 'none',
      borderRadius: '2rem',
      boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      color: '#000000',
      fontSize: '1.4rem',
      fontWeight: '600',
      iconColor: '#fec72b',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2.5rem',

      hoverBackground: '#f9f9fb',
      hoverBorder: 'none',
      hoverBoxShadow: '0 2px 15px rgba(0, 0, 0, 0.15)',
      hoverColor: '#828282',

      disabledColor: '#828282',
      disabledBackground: '#e5e5e5',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    'round-secondary': {
      height: '4rem',
      background: '#ffffff',
      border: 'none',
      borderRadius: '2rem',
      boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      color: '#000000',
      fontSize: '1.4rem',
      fontWeight: '600',
      iconColor: '#fec72b',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2.5rem',

      hoverBackground: '#338aff',
      hoverBorder: 'none',
      hoverBoxShadow: '0 2px 15px rgba(0, 0, 0, 0.15)',
      hoverColor: '#ffffff',

      disabledColor: '#828282',
      disabledBackground: '#e5e5e5',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    },
    delete: {
      height: '4rem',
      background: '#ffffff',
      border: 'none',
      borderRadius: '2rem',
      boxShadow: '0 1px 10px rgba(0, 0, 0, 0.08)',
      color: '#2f80ed',
      fontSize: '1.4rem',
      fontWeight: '600',
      iconColor: '#fec72b',
      iconHeight: '1.4rem',
      iconSpacing: '6px',
      padding: '0 2.5rem',

      hoverBackground: '#ffffff',
      hoverBorder: 'none',
      hoverBoxShadow: '0 2px 15px rgba(0, 0, 0, 0.15)',
      hoverColor: '#2f80ed',

      disabledColor: '#828282',
      disabledBackground: '#e5e5e5',
      disabledBorder: 'none',
      disabledBoxShadow: 'none'
    }
  }
}
