import { BackIcon } from '../../icons'
import { BackButtonStyled } from './BackButton.styled'

type BackButtonProps = {
  caption?: string
  onClick: () => void
}

export const BackButton = ({ caption, onClick }: BackButtonProps) => (
  <BackButtonStyled onClick={onClick} data-test='back-button'>
    <BackIcon />
    {caption ? <span>{caption}</span> : null}
  </BackButtonStyled>
)
