import { ReactNode, useContext } from 'react'

import cn from 'classnames'

import { ContinueIcon } from '../../icons'
import { ThemeContext } from 'styled-components'
import { ThreeCircles } from 'react-loader-spinner'
import { BankButtonStyled } from './CustomBankSelectItem.styled'

import { tr } from 'mmfintech-commons'

type CustomBankSelectItemProps = {
  back?: boolean
  icon?: ReactNode
  caption?: string
  description?: string
  selected: boolean
  preselected?: boolean
  onClick: () => void
  loading?: boolean
  maintenance?: boolean
  className?: string
}

export const CustomBankSelectItem = ({
  back,
  caption,
  description,
  icon,
  selected,
  preselected,
  onClick,
  loading,
  maintenance,
  className,
  ...rest
}: CustomBankSelectItemProps) => {
  const themeContext = useContext(ThemeContext)

  const handleClick = () => {
    if (!loading) {
      onClick()
    }
  }

  return (
    <BankButtonStyled
      onClick={handleClick}
      className={cn(className, { back, selected, preselected, maintenance })}
      {...rest}>
      {back ? (
        <span className='icon'>
          <ContinueIcon color='#d6dee8' stroke='#000000' />
        </span>
      ) : (
        <span className='icon'>{icon}</span>
      )}

      <span className='text'>
        <span className='caption'>{caption}</span>
        <span>{description}</span>
        {maintenance ? (
          <span className='warning'>{tr('CHECKOUT.VOUCHER.BANKS.MAINTENANCE', 'Under maintenance')}</span>
        ) : null}
      </span>

      {!back && selected ? (
        <span className='icon'>
          {loading ? (
            <ThreeCircles color={themeContext.whitelabel.header.borderTopColor} height='3rem' width='3rem' />
          ) : (
            <ContinueIcon
              color={themeContext.whitelabel.header.borderTopColor}
              stroke={themeContext.whitelabel.header.background}
            />
          )}
        </span>
      ) : null}
    </BankButtonStyled>
  )
}
