import cn from 'classnames'

import { MethodIconStyled } from './MethodIcon.styled'
import { PaymentMethod } from 'mmfintech-commons-types'

type MethodIconProps = {
  method: PaymentMethod
  selected?: boolean
}

export const MethodIcon = ({ method, selected }: MethodIconProps) => (
  <MethodIconStyled className={cn(`image-${method.toLowerCase()}`, { selected })} />
)
