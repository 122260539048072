import brandLogo from '../assets/images/mikado-logo.png'

import { commonTheme } from './commonTheme'
import { DefaultTheme } from 'styled-components'

export const mikadoTheme: DefaultTheme = {
  ...commonTheme,

  brandLogo,
  brandLogoMaxHeight: '3rem',

  bankSelectItem: {
    selectedBackgroundColor: '#EDF4FF',
    selectedBorderColor: '#7189CA'
  },

  whitelabel: {
    custom: {
      checkbox: {
        color: '#828b99',
        iconColor: '#2663fc',
        alignment: 'center'
      },
      radio: {
        borderBottom: '1px solid #f0f0f0',
        iconColor: '#31CDD9'
      }
    },
    header: {
      background: '#021E66',
      borderTop: '1.2rem solid #03fbcd',
      borderTopColor: '#03fbcd',
      color: '#b3d1f6',
      amountColor: '#ffffff',
      amountFont: 'Barlow',
      currencyColor: '#ffffff'
    },
    footer: {
      background: '#021e66',
      backgroundHover: '#021e66',
      backgroundSecondary: '#6996fb',
      color: '#ffffff',
      disabledColor: '#808080'
    },
    scrollbar: {
      background: 'rgba(27, 82, 156, 0.25)',
      borderRadius: '8px',
      width: '15px'
    },
    loader: {
      color: '#03FBCD'
    },
    maxWidth: '45rem'
  }
}
