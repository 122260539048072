// noinspection SpellCheckingInspection

import styled from 'styled-components'

import logoPix from '../../assets/methods/pix.png'
import logoUpi from '../../assets/methods/upi.png'
import logoJeton from '../../assets/methods/jeton.png'
import logoKhipu from '../../assets/methods/khipu.png'
import logoPayID from '../../assets/methods/pay-id.png'
import logoBoleto from '../../assets/methods/boleto.png'
import logoCrypto from '../../assets/methods/crypto.svg'
import logoInterac from '../../assets/methods/interac.png'
import logoPecFlash from '../../assets/methods/pec-flash.png'
import logoBTVoucher from '../../assets/methods/btvoucher.png'
import logoSafetyPay from '../../assets/methods/safetypay.png'
import logoCreditCard from '../../assets/methods/credit-card.png'
import logoJetonWallet from '../../assets/methods/jeton-wallet.png'
import logoBankTransfer from '../../assets/methods/bank-transfer.png'
import logoPagoEfectivo from '../../assets/methods/pago-efectivo.png'
import logoKingdomWallet from '../../assets/methods/kingdom-wallet.png'
import logoIndianNetBanking from '../../assets/methods/indian-net-banking.png'
import logoInstantBankTransfer from '../../assets/methods/instant-bank-transfer.png'

export const MethodIconStyled = styled.span`
  position: relative;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0;
  margin: 0;

  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  border-radius: 0;

  //prettier-ignore
  &.image-pix {
    background-image: url("${logoPix}");
  }
  //prettier-ignore
  &.image-upi {
    background-image: url("${logoUpi}");
  }
  //prettier-ignore
  &.image-jeton,
  &.image-jeton_cash {
    background-image: url("${logoJeton}");
  }
  //prettier-ignore
  &.image-jeton_wallet {
    background-image: url("${logoJetonWallet}");
    background-size: 140%;
  }
  //prettier-ignore
  &.image-khipu {
    background-image: url("${logoKhipu}");
  }
  //prettier-ignore
  &.image-pay_id {
    background-image: url("${logoPayID}");
    background-size: unset;
  }
  //prettier-ignore
  &.image-boleto {
    background-image: url("${logoBoleto}");
  }
  //prettier-ignore
  &.image-instant_crypto {
    background-image: url("${logoCrypto}");
  }
  //prettier-ignore
  &.image-interac {
    background-image: url("${logoInterac}");
  }
  //prettier-ignore
  &.image-pec_flash {
    background-image: url("${logoPecFlash}");
  }
  //prettier-ignore
  &.image-btvoucher {
    background-image: url("${logoBTVoucher}");
  }
  //prettier-ignore
  &.image-safetypay_bank,
  &.image-safetypay_cash {
    background-image: url("${logoSafetyPay}");
  }
  //prettier-ignore
  &.image-credit_card {
    background-image: url("${logoCreditCard}");
  }
  //prettier-ignore
  &.image-bankwire {
    background-image: url("${logoBankTransfer}");
    background-size: unset;
  }
  //prettier-ignore
  &.image-pago_efectivo_bank,
  &.image-pago_efectivo_cash {
    background-image: url("${logoPagoEfectivo}");
  }
  //prettier-ignore
  &.image-kingdom_wallet {
    background-image: url("${logoKingdomWallet}");
    background-size: unset;
  }
  //prettier-ignore
  &.image-indian_net_banking {
    background-image: url("${logoIndianNetBanking}");
  }
  //prettier-ignore
  &.image-instant_bank_transfer,
  &.image-direct_instant_bank_transfer,
  &.image-community_instant_bank_transfer {
    background-image: url("${logoInstantBankTransfer}");
    background-size: unset;
  }
`
