import { PaymentOption } from 'mmfintech-commons-types'
import { VoucherOption, VoucherStep } from '../../@types'

export enum VoucherStepEnum {
  OPTIONS = 'options',
  METHODS = 'methods',
  FIELDS = 'fields',
  BANKS = 'banks',
  REDEEM = 'redeem'
}

export interface UseVoucherFlowProps {
  handlePay: () => void
  hasAdditionalFields?: () => boolean
  hasBankSelection?: () => boolean
  hasPreselectedBank?: () => boolean
  paymentMethod?: string
  countryCode?: string
}

export interface UseVoucherFlowOutput {
  step: VoucherStep
  setStep: (value: VoucherStep) => void
  option: VoucherOption
  setOption: (value: VoucherOption) => void
  triggered: boolean
  methodBlixcard: PaymentOption
  filteredMethods: PaymentOption[]
  hasBack: (option: VoucherStep) => boolean
  hasCountrySelected: () => boolean
  hasOnlyBlixcard: () => boolean
  handleBack: () => void
  handleNext: () => void
  reset: () => void
  resetMethods: () => void
  continueWithPayment: () => void
}
