import styled from 'styled-components'

export const CustomRadioWrapper = styled.div`
  cursor: pointer;

  padding: 1rem 1.3rem;

  display: flex;
  align-items: center;
  flex-flow: row nowrap;

  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;

  .check {
    width: 24px;
    height: 24px;
    margin-right: 1.5rem;

    > img {
      @media (max-width: 480px) {
        width: 2rem;
      }

      @media (max-width: 240px) {
        width: 1.6rem;
      }
    }
  }

  .icon > img {
    @media (max-width: 480px) {
      width: 3.2rem;
    }

    @media (max-width: 240px) {
      width: 2.6rem;
    }
  }

  .text {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: 5px;

    .caption {
      font-size: 1.6rem;
      font-weight: 600;
      line-height: normal;

      @media (max-width: 480px) {
        font-size: 1.4rem;
      }
    }
    @media (max-width: 480px) {
      font-size: 1.2rem;
    }
  }
`
