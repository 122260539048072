import cn from 'classnames'

import { ContinueIcon } from '../../icons'
import { ThreeCircles } from 'react-loader-spinner'
import { MethodArrow, MethodButtonStyled, MethodContent, MethodIcon } from './MethodButton.styled'

type MethodButtonProps = {
  icon: any
  caption: string
  description?: string
  loading?: boolean
  selected: boolean
  disabled?: boolean
  onClick: () => void
}

export const MethodButton = ({
  icon,
  caption,
  description,
  loading,
  selected,
  disabled,
  onClick,
  ...rest
}: MethodButtonProps) => {
  return (
    <MethodButtonStyled className={cn({ selected: selected && !disabled, disabled })} onClick={onClick} {...rest}>
      <MethodIcon>{typeof icon === 'string' ? <img src={icon} alt='' /> : icon}</MethodIcon>
      <MethodContent>
        <div className='caption'>{caption}</div>
        {description ? <div className='description'>{description}</div> : null}
      </MethodContent>
      {(selected || loading) && !disabled ? (
        <MethodArrow>
          {loading ? (
            <ThreeCircles color='#55419b' height='3rem' width='3rem' />
          ) : (
            <ContinueIcon color='#f6d34c' stroke='#55419b' />
          )}
        </MethodArrow>
      ) : null}
    </MethodButtonStyled>
  )
}
