import styled from 'styled-components'

export const BlixcardHeaderBack = styled.div`
  width: 100%;
  height: 7.5rem;
  background: #f6d34c;
  border-radius: 12px 12px 0 0;
`

export const BlixcardHeader = styled.div`
  width: 100%;
  height: 7.2rem;
  border-radius: 12px 12px 0 0;
  background: #55419b;
  margin-top: -6.7rem;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.6rem 1.6rem 0;

  img {
    max-height: 1.4rem;
  }

  //@media (max-width: 480px) {
  //  margin-right: 4rem;
  //}
`

export const BlixcardAmount = styled.div`
  background: #f2f2f5;
  border-radius: 1.2rem;
  height: 7rem;

  position: absolute;
  top: 5.8rem;
  left: 1.6rem;
  right: 1.6rem;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  & > div {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    gap: 8px;
  }

  .amount {
    color: #373942;
    font-size: 3rem;
    font-weight: 400;
    line-height: normal;
  }
  .currency {
    color: #373942;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 5px;
  }
`
