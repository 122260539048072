import parse from 'html-react-parser'
// noinspection ES6CheckImport
import domPurify from 'dompurify'

import { SuccessIcon } from '../../../icons'
import { SuccessMessageBody, SuccessMessageTitle, SuccessMessageWrapper } from './PreviewMessage.styled'

import { tr } from 'mmfintech-commons'

export const PreviewMessage = ({ result }) => {
  const message = typeof result === 'string' ? result.replace(/\r?\n/g, '<br/>').replace(/\\n/g, '<br/>') : ''

  return (
    <SuccessMessageWrapper>
      <SuccessIcon />
      <SuccessMessageTitle>{tr('FRONTEND.DEPOSIT.RESULT.SUCCESS', 'Success')}</SuccessMessageTitle>
      <SuccessMessageBody>{parse(domPurify.sanitize(message))}</SuccessMessageBody>
    </SuccessMessageWrapper>
  )
}
