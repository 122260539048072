import { commonTheme } from './commonTheme'
import { mikadoTheme } from './mikadoTheme.js'
import { tazukiTheme } from './tazukiTheme'
import { konipayTheme } from './konipayTheme.js'
import { blixcardTheme } from './blixcardTheme.js'
import { cardakinaTheme } from './cardakinaTheme.js'

import settings, { isProduction } from '../settings'

const themeBuilder = () => {
  switch (settings.brand) {
    case 'BLIXCARD':
      return blixcardTheme
    case 'CARDAKINA':
      return cardakinaTheme
    case 'MIKADO':
      return mikadoTheme
    case 'TAZUKI':
      return tazukiTheme

    case 'KONIPAY':
      return konipayTheme

    default:
      if (!isProduction) {
        console.log(`themeBuilder: no definitions for ${settings.brand}`)
      }
      return commonTheme
  }
}

export default themeBuilder
