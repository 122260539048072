import { ReactNode, useEffect } from 'react'
import cn from 'classnames'

import { ModalCloseIcon } from '../../icons'
import { ModalContainer, ModalWrapper } from './ModalDialog.styled'

import { useModal } from 'mmfintech-commons'
import { useLocation } from 'react-router-dom'
import { UseModalOptions } from 'mmfintech-commons/dist/hooks/useModal/useModal.types'

type ModalDialogProps = {
  content: ReactNode
  options?: UseModalOptions
  visible?: boolean
  onClose?: () => void
}

export const ModalDialog = ({ content, options, visible, onClose }: ModalDialogProps) => {
  const { ref, width, checkWidth, handleClose, overflowing, modalOptions, handleMouseDown } = useModal({
    options,
    visible,
    onClose
  })

  const location = useLocation()

  useEffect(() => {
    if (visible) {
      typeof onClose === 'function' && onClose()
    }
    // eslint-disable-next-line
  }, [location.pathname])

  if (visible) {
    return (
      <ModalWrapper
        onMouseDown={handleMouseDown}
        className={cn({ overflowing: overflowing || width < checkWidth })}
        ref={ref}>
        <ModalContainer
          className={cn('icon-' + modalOptions.closeIconPosition, { scrollable: modalOptions.scrollable })}
          checkWidth={checkWidth}>
          <div className='popup-main-content' onMouseDown={e => e.stopPropagation()}>
            {content}
            {!modalOptions.hideCloseIcon && (
              <div className='popup-close-button-inline' onClick={handleClose}>
                <ModalCloseIcon />
              </div>
            )}
          </div>
          {!modalOptions.hideCloseIcon && (
            <div className='popup-close-button' data-test='popup-close-button' onClick={handleClose}>
              <ModalCloseIcon />
            </div>
          )}
        </ModalContainer>
      </ModalWrapper>
    )
  }

  return null
}
