export const ContinueIcon = ({ color = '#f6d34c', stroke = '#55419b' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'>
    <circle cx='23.6074' cy='23.6074' r='23.6074' fill={color} />
    <path
      d='M22.0531 19L27.6471 23.6836L22 28.4118'
      stroke={stroke}
      strokeWidth='1.88235'
      strokeLinecap='square'
      strokeLinejoin='round'
    />
  </svg>
)
