import styled from 'styled-components'

export const PreviewTitle = styled.div`
  color: #454a54;
  font-size: 1.6rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 2.4rem;

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`

export const PreviewGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: auto 1fr;
  row-gap: 0.8rem;
  margin-bottom: 3rem;

  color: #000000;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  .label {
    color: #818389;
  }
`

export const WarningMessageWrapper = styled.div`
  background: #f2f2f5;
  border-radius: 5px;
  padding: 2rem 2rem 2.5rem;
  margin: 3rem 0;

  color: #454a54;
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`
