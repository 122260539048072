import { commonTheme } from './commonTheme'
import { DefaultTheme } from 'styled-components'

export const konipayTheme: DefaultTheme = {
  ...commonTheme,

  brandLogo: '',
  brandLogoText: 'KONIPAY',
  brandLogoMaxHeight: '3rem',

  whitelabel: {
    custom: {
      checkbox: {
        color: '#8d9dad',
        iconColor: '#8b4513',
        alignment: 'center'
      },
      radio: {
        borderBottom: '1px solid #f0f0f0',
        iconColor: '#8b4513'
      }
    },
    header: {
      background: '#8b4513',
      borderTop: '1.2rem solid #fe9931',
      borderTopColor: '#fe9931',
      borderBottom: '0.8rem solid #fe9931',
      color: '#9db8cb',
      amountColor: '#ffffff',
      amountFont: 'Barlow',
      currencyColor: '#9db8cb'
    },
    footer: {
      background: '#800000',
      backgroundHover: '#800000',
      backgroundSecondary: '#800000',
      color: '#ffffff',
      disabledColor: '#808080'
    },
    scrollbar: {
      background: 'rgba(10, 70, 107, 0.25)',
      borderRadius: '7px',
      width: '14px'
    },
    maxWidth: '45rem'
  }
}
