import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { SearchIcon } from '../../../icons'
import { ErrorDisplay, Input, Spinner } from 'mmfintech-portal-commons'
import { BanksContainer, BanksMessage, VoucherTitle } from './CheckoutVoucher.styled'
import { BackButton, CheckoutVoucherLoading, CustomBankSelectItem } from '../../../components'

import { isBase64EncodedImage, tr } from 'mmfintech-commons'
import {
  configuration,
  extractBankName,
  extractBankNameDomestic,
  UseFilteredBanksResult
} from 'mmfintech-checkout-commons'

import { CountryChoice, SupportedBank } from 'mmfintech-commons-types'

import BankGroupIcon from '../../../assets/icons/bank-group.png'
import NoLogoBankIcon from '../../../assets/icons/no-logo-bank.png'

interface VoucherBanksStepProps {
  filteredBanks: UseFilteredBanksResult
  countryCode: string
  setCountryCode: (arg: string) => void
  formValues: any
  handleBack: () => void
  handleContinue: () => void
  logBankSearchFocus: () => void
  logBankSearchType: () => void
}

export const VoucherBanksStep: React.FC<VoucherBanksStepProps> = ({
  filteredBanks,
  countryCode,
  setCountryCode,
  formValues,
  handleBack,
  handleContinue,
  logBankSearchFocus,
  logBankSearchType
}: VoucherBanksStepProps) => {
  const {
    banksCount,
    banksError,
    checkoutBanksFetching,
    checkoutCountries,
    checkoutError,
    checkoutPayFetching,
    session
  } = useSelector(
    ({
      checkout: {
        banksCount,
        banksError,
        checkoutBanksFetching,
        checkoutCountries,
        checkoutError,
        checkoutPayFetching,
        session
      }
    }) => ({
      banksCount,
      banksError,
      checkoutBanksFetching,
      checkoutCountries,
      checkoutError,
      checkoutPayFetching,
      session
    }),
    shallowEqual
  )

  const isCountrySelectionVisible = () =>
    Array.isArray(checkoutCountries) &&
    checkoutCountries.length > 1 &&
    !(session?.country && checkoutCountries.find(c => c.countryCode === session.country))

  const prepareIcon = (bank: SupportedBank) => {
    if (bank.logoUrl) {
      return (
        <img
          src={
            isBase64EncodedImage(bank.logoUrl) || bank.logoUrl.substring(0, 4) === 'http'
              ? bank.logoUrl
              : configuration.readBackendConfig() + bank.logoUrl
          }
          alt=''
        />
      )
    }

    return <img src={Array.isArray(bank.options) && bank.options ? BankGroupIcon : NoLogoBankIcon} alt='' />
  }

  return (
    <>
      <div className='mb-2'>
        <BackButton onClick={handleBack} />
      </div>

      <VoucherTitle data-test='pay-with-INSTANT_BANK_TRANSFER-banks'>
        {tr('CHECKOUT.VOUCHER.BANKS.TITLE_IBT', 'Pay with Instant Bank Transfer')}
      </VoucherTitle>

      <ErrorDisplay error={[checkoutError, banksError]} />

      {isCountrySelectionVisible() ? (
        <Input
          type='select'
          name='countryCode'
          data-test='country-select'
          label={tr('CHECKOUT.PAYMENT.COUNTRY_PLACEHOLDER', 'Select country')}
          options={checkoutCountries?.map((country: CountryChoice) => ({
            value: country.countryCode,
            label: country.name
          }))}
          value={countryCode}
          onChange={(_name: string, value: string) => setCountryCode(value)}
          hideErrorLine
        />
      ) : null}

      {checkoutBanksFetching ? (
        <CheckoutVoucherLoading>
          <Spinner />
        </CheckoutVoucherLoading>
      ) : (
        <div>
          {filteredBanks.hasBack ? (
            <div className='text-right'>
              <BackButton onClick={filteredBanks.handleBack} caption={tr('FRONTEND.BUTTONS.BACK', 'Back')} />
            </div>
          ) : null}

          {countryCode && banksCount > 10 ? (
            <div className='bank-search'>
              <Input
                key='bank-search'
                type='text'
                name='bank-search'
                maxLength={50}
                value={filteredBanks.bankSearchText}
                onChange={(_name: string, value: string) => {
                  filteredBanks.setBankSearchText(value)
                  logBankSearchType()
                }}
                onFocus={logBankSearchFocus}
                placeholder={
                  countryCode === 'DEU'
                    ? tr('CHECKOUT.PAYMENT.BANK_SEARCH_DEU', 'Search by bank name or BLZ')
                    : tr('CHECKOUT.PAYMENT.BANK_SEARCH', 'Search by bank name')
                }
                hideErrorLine
                autoComplete='off'
                icon={<SearchIcon />}
                //@ts-ignore
                iconPosition='start'
                data-test='bank-search-input'
              />
            </div>
          ) : null}

          <BanksContainer>
            {Array.isArray(filteredBanks.banks) && filteredBanks.banks.length ? (
              filteredBanks.banks.map((bank: SupportedBank, index: number) => {
                const { bankChoiceId, underMaintenance } = bank
                const selected = formValues.getValue('bankChoiceId') === bankChoiceId
                return (
                  <CustomBankSelectItem
                    key={bankChoiceId}
                    caption={extractBankName(bank)}
                    description={extractBankNameDomestic(bank)}
                    selected={selected}
                    onClick={() => (selected ? handleContinue() : filteredBanks.handleSelectBank(bank))}
                    icon={prepareIcon(bank)}
                    loading={checkoutPayFetching}
                    maintenance={underMaintenance}
                    preselected={
                      index < filteredBanks.banks.length - 1 &&
                      filteredBanks.banks[index + 1].bankChoiceId === formValues.getValue('bankChoiceId')
                    }
                    data-test={`bank-${bankChoiceId}`}
                  />
                )
              })
            ) : !filteredBanks.treeMode ? (
              <BanksMessage>
                {filteredBanks.bankSearchText?.length > 2
                  ? tr('CHECKOUT.PAYMENT.NO_BANKS_FOUND', 'No banks found')
                  : tr('CHECKOUT.PAYMENT.SEARCH_BANK_HINT', 'Type 3 or more characters to filter banks…')}
              </BanksMessage>
            ) : null}
          </BanksContainer>
        </div>
      )}
    </>
  )
}
