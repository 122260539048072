import { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { actions } from 'mmfintech-checkout-commons'
import { isValidArray } from 'mmfintech-commons'

import { VoucherOption, VoucherStep } from '../../@types'
import { CountryChoice, PaymentOption } from 'mmfintech-commons-types'
import { UseVoucherFlowOutput, UseVoucherFlowProps, VoucherStepEnum } from './useVoucherFlow.types'

export const useVoucherFlow = (props: UseVoucherFlowProps): UseVoucherFlowOutput => {
  const { handlePay, hasAdditionalFields, hasBankSelection, hasPreselectedBank, paymentMethod, countryCode } = props

  const { checkoutCountries, checkoutOptions, checkoutError, session } = useSelector(
    ({ checkout: { checkoutCountries, checkoutOptions, checkoutError, session } }) => ({
      checkoutCountries,
      checkoutOptions,
      checkoutError,
      session
    }),
    shallowEqual
  )

  const [step, setStep] = useState<VoucherStep>('none')
  const [option, setOption] = useState<VoucherOption>('buy')
  const [triggered, setTriggered] = useState(false)
  const [methodBlixcard, setMethodBlixcard] = useState<PaymentOption>(null)
  const [filteredMethods, setFilteredMethods] = useState<PaymentOption[]>([])

  const dispatch = useDispatch()

  const { country: sessionCountryCode, skipVoucherOptionSelection } = session || {}

  const hasOnlyBlixcard = () => !isValidArray(filteredMethods) && !!methodBlixcard
  const shouldSkipSelection = () => !!skipVoucherOptionSelection || hasPreselectedBank()
  const fieldsAfterBanks = () => countryCode === 'KOR'

  const hasCountrySelected = () =>
    sessionCountryCode?.length && !!checkoutCountries?.find((c: CountryChoice) => c.countryCode === sessionCountryCode)
  // const hasCountrySelection = checkoutCountries?.length > 1 && (!hasCountrySelected() || !shouldSkipSelection())
  const hasCountrySelection = checkoutCountries?.length > 1 && !hasCountrySelected()
  const hasMethodSelection = () => hasCountrySelection || filteredMethods?.length > 1

  const handleContinueWithPayment = () => {
    if (!triggered) {
      if (paymentMethod) {
        setTriggered(true)
        handlePay()
      } else {
        dispatch(actions.checkout.fetchSessionError('No payment methods available', 'CHECKOUT.PAYMENT.NO_METHODS'))
      }
    }
  }

  const hasBack = (currentStep: VoucherStep): boolean => {
    switch (currentStep) {
      case VoucherStepEnum.BANKS:
        return (fieldsAfterBanks() ? hasMethodSelection() : hasAdditionalFields()) || !skipVoucherOptionSelection

      case VoucherStepEnum.FIELDS:
        return (fieldsAfterBanks() ? hasBankSelection() : hasMethodSelection()) || !skipVoucherOptionSelection

      case VoucherStepEnum.METHODS:
      case VoucherStepEnum.REDEEM:
        return !skipVoucherOptionSelection

      default:
      // nothing
    }

    return false
  }

  const handleBack = () => {
    dispatch(actions.checkout.cleanupPaymentError())
    switch (step) {
      case VoucherStepEnum.METHODS:
      case VoucherStepEnum.REDEEM:
        setStep(VoucherStepEnum.OPTIONS)
        return

      case VoucherStepEnum.BANKS:
        if (!fieldsAfterBanks()) {
          if (hasAdditionalFields()) {
            setStep(VoucherStepEnum.FIELDS)
            return
          }
          if (hasMethodSelection()) {
            setStep(VoucherStepEnum.METHODS)
            return
          }
        }
        setStep(VoucherStepEnum.OPTIONS)
        return

      case VoucherStepEnum.FIELDS:
        if (fieldsAfterBanks() && hasBankSelection()) {
          setStep(VoucherStepEnum.BANKS)
          return
        }
        if (hasMethodSelection()) {
          setStep(VoucherStepEnum.METHODS)
          return
        }
        setStep(VoucherStepEnum.OPTIONS)
        return
    }
  }

  const assignNextStep = (currentStep: string): void => {
    if (currentStep === 'none' && !shouldSkipSelection()) {
      setStep(VoucherStepEnum.OPTIONS)
      return
    }

    if (step === VoucherStepEnum.OPTIONS && option === 'redeem') {
      setStep(VoucherStepEnum.REDEEM)
      return
    }

    if (currentStep === 'none' || currentStep === VoucherStepEnum.OPTIONS) {
      if (hasMethodSelection()) {
        setStep(VoucherStepEnum.METHODS)
        return
      }
    }

    if (currentStep === 'none' || currentStep === VoucherStepEnum.OPTIONS || currentStep === VoucherStepEnum.METHODS) {
      if (fieldsAfterBanks()) {
        if (hasBankSelection()) {
          setStep(VoucherStepEnum.BANKS)
          return
        }
      } else {
        if (hasAdditionalFields()) {
          setStep(VoucherStepEnum.FIELDS)
          return
        }
      }
    }

    if (fieldsAfterBanks()) {
      if (hasAdditionalFields() && currentStep !== VoucherStepEnum.FIELDS) {
        setStep(VoucherStepEnum.FIELDS)
        return
      }
    } else {
      if (hasBankSelection() && currentStep !== VoucherStepEnum.BANKS) {
        setStep(VoucherStepEnum.BANKS)
        return
      }
    }

    if (session) {
      handleContinueWithPayment()
    }
  }

  const initialize = () => {
    step === 'none' && assignNextStep(step)
  }

  const reset = () => {
    setStep('none')
    setOption('buy')
    setTriggered(false)
  }

  const resetMethods = () => {
    setMethodBlixcard(null)
    setFilteredMethods([])
  }

  useEffect(() => {
    initialize()
  }, [])

  useEffect(() => {
    if (Array.isArray(checkoutOptions)) {
      const blixcard = Array.isArray(checkoutOptions)
        ? checkoutOptions.find(option => option.paymentMethod === 'BLIXCARD')
        : null
      setMethodBlixcard(blixcard)
      const methods = Array.isArray(checkoutOptions)
        ? checkoutOptions.filter(option => option.paymentMethod !== 'BLIXCARD')
        : []
      setFilteredMethods(methods)

      if (!isValidArray(methods) && blixcard) {
        setOption('redeem')
        setStep('redeem')
      }
    } else {
      setMethodBlixcard(null)
      setFilteredMethods([])
    }
    // eslint-disable-next-line
  }, [checkoutOptions])

  useEffect(() => {
    if (checkoutError) {
      if (triggered) {
        setTriggered(false)
      }
      initialize()
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }, [checkoutError])

  return {
    step,
    setStep,
    option,
    setOption,
    triggered,
    methodBlixcard,
    filteredMethods,
    hasBack,
    hasCountrySelected,
    hasOnlyBlixcard,
    handleBack,
    handleNext: () => assignNextStep(step),
    reset,
    resetMethods,
    continueWithPayment: handleContinueWithPayment
  }
}
