import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { CustomInput } from '../../../components/CustomInput'
import { VoucherTitle } from './Blixcard.styled'
import { BackButton, Loader } from '../../../components'
import { ErrorDisplay, Input } from 'mmfintech-portal-commons'

import { tr } from 'mmfintech-commons'

import { CheckoutMethodField, CountryChoice } from 'mmfintech-commons-types'

type BlixcardRedeemStepProps = {
  paymentMethod: string
  countryCode: string
  setCountryCode: (code: string) => void
  fields: CheckoutMethodField[]
  formValues: any
  hideBack: () => boolean
  handleBack: () => void
  handleNext: () => void
}

export const BlixcardRedeemStep: React.FC<BlixcardRedeemStepProps> = ({
  paymentMethod,
  countryCode,
  setCountryCode,
  fields,
  formValues,
  hideBack,
  handleBack,
  handleNext
}) => {
  const { checkoutCountries, checkoutError, checkoutPayFetching, session } = useSelector(
    ({ checkout: { checkoutCountries, checkoutError, checkoutPayFetching, session } }) => ({
      checkoutCountries,
      checkoutError,
      checkoutPayFetching,
      session
    }),
    shallowEqual
  )

  const { country: sessionCountryCode } = session || {}

  return (
    <>
      {hideBack() ? null : (
        <div className='mb-2'>
          <BackButton onClick={handleBack} />
        </div>
      )}

      <VoucherTitle datatype='redeem-voucher-page'>
        {tr('CHECKOUT.BLIXCARD.REDEEM.TITLE', 'Redeem Blixcard')}
      </VoucherTitle>

      <ErrorDisplay error={[checkoutError]} />

      <div>
        {checkoutCountries?.length > 1 && !sessionCountryCode ? (
          <CustomInput
            type='select'
            name='countryCode'
            data-test='country-select'
            label={tr('CHECKOUT.VOUCHER.REDEEM.COUNTRY', 'Select country')}
            options={checkoutCountries?.map((country: CountryChoice) => ({
              value: country.countryCode,
              label: country.name
            }))}
            value={countryCode}
            onChange={(_name: string, value: string) => setCountryCode(value)}
          />
        ) : null}

        {Array.isArray(fields) && fields.length
          ? fields.map((item, index) => {
              const { name, type, defaultLabel, translationKey, required, sensitive, options } = item

              if (name === 'bankChoiceId') {
                return null
              }

              if (type.toLowerCase() === 'select') {
                const extractedOptions =
                  typeof options === 'object' ? Object.entries(options).map(([value, label]) => ({ value, label })) : []
                return (
                  <Input
                    key={index}
                    type='select'
                    data-test={`${name}-select`}
                    options={extractedOptions}
                    label={tr(translationKey, defaultLabel)}
                    {...formValues.registerInput(name)}
                    required={required}
                    hideErrorLine
                    menuPlacement='top'
                  />
                )
              }

              return (
                <CustomInput
                  key={index}
                  type={sensitive ? 'password' : 'text'}
                  data-test={`${name}-input`}
                  label={tr(translationKey, defaultLabel)}
                  {...formValues.registerInput(name)}
                  required={required}
                />
              )
            })
          : null}
      </div>

      <div className='link-container'>
        <a href='https://portal.blixcard.com'>
          {tr('CHECKOUT.VOUCHER.REDEEM.PURCHASE_HERE', 'Purchase Blixcard Here')}
        </a>
      </div>

      <div className='buttons-container mt-2'>
        <button
          disabled={!paymentMethod || checkoutPayFetching}
          className='continue-btn'
          onClick={handleNext}
          data-test='continue-button'>
          {checkoutPayFetching ? <Loader /> : tr('FRONTEND.BUTTONS.CONTINUE', 'Continue')}
        </button>
      </div>
    </>
  )
}
