import React, { ReactNode, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { CustomSearch } from '../../../components/CustomInput'
import { LogEventCallback } from '../../../@types'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'
import { BanksContainer, BanksMessage, VoucherTitle } from './Blixcard.styled'
import { BackButton, CheckoutVoucherLoading, CustomBankSelectItem } from '../../../components'

import { configuration, UseFilteredBanksResult } from 'mmfintech-checkout-commons'
import { isBase64EncodedImage, tr, useDelayedExecute } from 'mmfintech-commons'

import { SupportedBank } from 'mmfintech-commons-types'

import BankGroupIcon from '../../../assets/icons/bank-group.png'
import NoLogoBankIcon from '../../../assets/icons/no-logo-bank.png'

type BlixcardBanksStepProps = {
  countryCode: string
  paymentMethod: string
  filteredBanks: UseFilteredBanksResult
  formValues: any
  handleBack: () => void
  handleNext: () => void
  logEvent: LogEventCallback
}

export const BlixcardBanksStep: React.FC<BlixcardBanksStepProps> = ({
  countryCode,
  paymentMethod,
  filteredBanks,
  formValues,
  handleBack,
  handleNext,
  logEvent
}) => {
  const { banksCount, banksError, checkoutBanksFetching, checkoutError, checkoutPayFetching } = useSelector(
    ({ checkout: { banksCount, banksError, checkoutBanksFetching, checkoutError, checkoutPayFetching } }) => ({
      banksCount,
      banksError,
      checkoutBanksFetching,
      checkoutError,
      checkoutPayFetching
    }),
    shallowEqual
  )

  const [eventLogged, setEventLogged] = useState(false)

  const extractName = (bank: SupportedBank): string =>
    ['JPN', 'KOR'].includes(bank.countryCode) && bank.nameEnglish ? bank.nameEnglish : bank.name
  const extractDescription = (bank: SupportedBank): string =>
    ['JPN', 'KOR'].includes(bank.countryCode) && bank.nameEnglish ? bank.name : null

  const hasOptions = (bank: SupportedBank): boolean => Array.isArray(bank.options) && bank.options.length > 0

  const prepareIcon = (bank: SupportedBank): ReactNode => {
    if (bank.logoUrl) {
      return (
        <img
          src={
            isBase64EncodedImage(bank.logoUrl) || bank.logoUrl.substring(0, 4) === 'http'
              ? bank.logoUrl
              : configuration.readBackendConfig() + bank.logoUrl
          }
          alt=''
        />
      )
    }

    return <img src={hasOptions(bank) ? BankGroupIcon : NoLogoBankIcon} alt='' />
  }

  const logBankSearchFocus = () => logEvent('bank_search_focus', null, true)

  const logBankSearchType = () => {
    if (!eventLogged) {
      logEvent('bank_search_type', null, true)
      setEventLogged(true)
    }
  }

  const delayedLog = useDelayedExecute({
    name: 'delayedEventLogging',
    onExecute: () => {
      logEvent('banks_scrolling', '', true)
    }
  })

  const handleScroll = () => {
    delayedLog.execute()
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      logEvent('banks_scrolled_to_bottom', '', true)
    }
  }

  const handleSelect = (bank: SupportedBank): void => {
    if (!bank.underMaintenance || hasOptions(bank)) {
      filteredBanks.handleSelectBank(bank)
    }
  }

  const handleSubmit = () => {
    formValues.setRequired('bankChoiceId', true)
    handleNext()
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {typeof handleBack === 'function' ? (
        <div className='mb-2'>
          <BackButton onClick={handleBack} />
        </div>
      ) : null}

      <VoucherTitle data-test={`pay-with-${paymentMethod}-banks`}>
        {tr('CHECKOUT.VOUCHER.BANKS.TITLE', 'Pay with {{METHOD}}', {
          METHOD: tr(`METADATA.PAYMENT_OPTIONS.${paymentMethod}`, paymentMethod)
        })}
      </VoucherTitle>

      <ErrorDisplay error={[checkoutError, banksError]} />

      {checkoutBanksFetching ? (
        <CheckoutVoucherLoading>
          <Spinner />
        </CheckoutVoucherLoading>
      ) : (
        <div>
          {paymentMethod && banksCount > 10 ? (
            <div className='bank-search'>
              <CustomSearch
                key='bank-search'
                name='bank-search'
                data-test='bank-search-input'
                maxLength={50}
                label={
                  countryCode === 'DEU'
                    ? tr('CHECKOUT.PAYMENT.BANK_SEARCH_DEU', 'Search by bank name or BLZ')
                    : tr('CHECKOUT.PAYMENT.BANK_SEARCH', 'Search by bank name')
                }
                value={filteredBanks.bankSearchText}
                onChange={(_name: string, value: string) => {
                  filteredBanks.setBankSearchText(value)
                  logBankSearchType()
                }}
                onFocus={logBankSearchFocus}
                autoComplete='off'
              />
            </div>
          ) : null}

          <BanksContainer>
            {filteredBanks.hasBack ? (
              <CustomBankSelectItem
                back
                selected
                data-test='banks-back'
                onClick={filteredBanks.handleBack}
                caption={extractName(filteredBanks.selectedGroup)}
                description={extractDescription(filteredBanks.selectedGroup)}
              />
            ) : null}

            {Array.isArray(filteredBanks.banks) && filteredBanks.banks.length ? (
              filteredBanks.banks.map((bank: SupportedBank, index: number) => {
                const { bankChoiceId, underMaintenance } = bank
                const selected = formValues.getValue('bankChoiceId') === bankChoiceId
                return (
                  <CustomBankSelectItem
                    key={bankChoiceId}
                    data-test={`bank-${bankChoiceId}`}
                    caption={extractName(bank)}
                    description={extractDescription(bank)}
                    selected={selected}
                    onClick={() => (selected ? handleSubmit() : handleSelect(bank))}
                    icon={prepareIcon(bank)}
                    loading={checkoutPayFetching}
                    maintenance={underMaintenance}
                    preselected={
                      index < filteredBanks.banks.length - 1 &&
                      filteredBanks.banks[index + 1].bankChoiceId === formValues.getValue('bankChoiceId')
                    }
                    className={filteredBanks.hasBack ? 'bank-inner' : ''}
                  />
                )
              })
            ) : !filteredBanks.treeMode ? (
              <BanksMessage>
                {filteredBanks.bankSearchText?.length > 2
                  ? tr('CHECKOUT.PAYMENT.NO_BANKS_FOUND', 'No banks found')
                  : tr('CHECKOUT.PAYMENT.SEARCH_BANK_HINT', 'Type 3 or more characters to filter banks…')}
              </BanksMessage>
            ) : null}
          </BanksContainer>
        </div>
      )}
    </>
  )
}
