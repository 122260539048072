import React, { useContext } from 'react'
import { Circles } from 'react-loader-spinner'

import { ThemeContext } from 'styled-components'

type LoaderProps = {
  size?: {
    width?: string
    height?: string
  }
  color?: 'main' | 'secondary'
}

export const Loader: React.FC<LoaderProps> = () => {
  const theme = useContext(ThemeContext)

  return (
    <Circles
      height={25}
      width={25}
      color={theme.whitelabel.loader.color}
      ariaLabel='circles-loading'
      wrapperStyle={{ position: 'absolute' }}
      wrapperClass=''
      visible={true}
    />
  )
}
