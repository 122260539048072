import { PreviewPayId } from './PreviewPayId'

import { BaseInstructionsResult, PaymentMethodEnum, PaymentSessionData } from 'mmfintech-commons-types'

interface PreviewInstructionsProps {
  result: BaseInstructionsResult
  session?: PaymentSessionData
  startPolling: () => void
}

export const PreviewInstructions = ({ result, startPolling }: PreviewInstructionsProps) => {
  // const { successUrl } = session || {}
  const { paymentMethod } = result || {}

  // const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  // const handleRedirect = () => {
  //   void dispatch(actions.common.eventTrack(sessionId, 'redirected_back_to_merchant'))
  //   window.location.href = successUrl
  // }

  // const RedirectButton = () => (
  //   <>
  //     {successUrl ? (
  //       <div className='buttons'>
  //         <Button
  //           type='button'
  //           color='primary'
  //           onClick={handleRedirect}
  //           text={tr('FRONTEND.BUTTONS.CLOSE', 'Close')}
  //           data-test='button-back'
  //         />
  //       </div>
  //     ) : null}
  //   </>
  // )

  if (paymentMethod === PaymentMethodEnum.PAY_ID) {
    startPolling()
    return (
      <>
        <PreviewPayId result={result} />
      </>
    )
  }

  return null
}
