import styled from 'styled-components'

export const BackButtonStyled = styled.div`
  cursor: pointer;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;

  color: #a3b1bf;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  svg {
    margin-bottom: 1px;
  }
`
