import React, { ReactNode } from 'react'

interface LabelValueProps {
  label?: string
  value?: ReactNode
  dataTest?: string
}

export const LabelValue: React.FC<LabelValueProps> = ({ label, value, dataTest }) => {
  if (value) {
    return (
      <>
        <div className='label'>{label}</div>
        <div data-test={dataTest}>{value}</div>
      </>
    )
  }
  return null
}
