import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { CustomInput } from '../../../components/CustomInput'
import { ErrorDisplay } from 'mmfintech-portal-commons'
import { BackButton, MethodButton, MethodIcon } from '../../../components'
import { MethodSelectionContainer, VoucherTitle } from './Blixcard.styled'

import { tr } from 'mmfintech-commons'

import { CountryChoice, PaymentOption } from 'mmfintech-commons-types'

type BlixcardMethodStepProps = {
  countryCode: string
  setCountryCode: (code: string) => void
  hasCountrySelected: () => boolean
  paymentMethod: string
  setPaymentMethod: (value: string) => void
  filteredMethods: PaymentOption[]
  handleBack: () => void
  handleNext: () => void
  skipVoucherOptionSelection?: boolean
}

export const BlixcardMethodStep: React.FC<BlixcardMethodStepProps> = ({
  countryCode,
  setCountryCode,
  hasCountrySelected,
  paymentMethod,
  setPaymentMethod,
  filteredMethods,
  handleBack,
  handleNext,
  skipVoucherOptionSelection
}) => {
  const { checkoutCountries, checkoutError, checkoutPayFetching } = useSelector(
    ({ checkout: { checkoutCountries, checkoutError, checkoutPayFetching } }) => ({
      checkoutCountries,
      checkoutError,
      checkoutPayFetching
    }),
    shallowEqual
  )

  return (
    <>
      {typeof handleBack === 'function' ? (
        <div className='mb-2'>
          <BackButton onClick={handleBack} />
        </div>
      ) : null}

      <VoucherTitle data-test='select-method-page'>{tr('CHECKOUT.VOUCHER.METHOD.TITLE', 'Select method')}</VoucherTitle>

      <ErrorDisplay error={[checkoutError]} />

      <div>
        {checkoutCountries?.length > 1 && (!hasCountrySelected() || skipVoucherOptionSelection) ? (
          <CustomInput
            type='select'
            name='countryCode'
            data-test='country-select'
            label={tr('CHECKOUT.PAYMENT.COUNTRY_PLACEHOLDER', 'Select country')}
            options={checkoutCountries?.map((country: CountryChoice) => ({
              value: country.countryCode,
              label: country.name
            }))}
            value={countryCode}
            onChange={(_name: string, value: string) => setCountryCode(value)}
          />
        ) : null}

        <MethodSelectionContainer>
          {filteredMethods.map((method: PaymentOption) => {
            const selected = method.paymentMethod === paymentMethod

            return (
              <MethodButton
                key={method.paymentMethod}
                data-test={`method-${method.paymentMethod}`}
                icon={<MethodIcon method={method.paymentMethod} />}
                caption={tr(`METADATA.PAYMENT_OPTIONS.${method.paymentMethod}`, method.paymentMethod)}
                loading={selected && checkoutPayFetching}
                selected={selected}
                onClick={() => (selected ? handleNext() : setPaymentMethod(method.paymentMethod))}
              />
            )
          })}
        </MethodSelectionContainer>
      </div>
    </>
  )
}
