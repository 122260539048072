export const SuccessIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='83' height='63' viewBox='0 0 83 63' fill='none'>
    <circle cx='42.1367' cy='31.5' r='31.5' transform='rotate(180 42.1367 31.5)' fill='url(#paint0_linear_3749_1000)' />
    <circle cx='42.1359' cy='31.5002' r='21.6818' fill='#5BC586' />
    <path
      d='M31.9092 31.0907L39.6819 39.6816L52.3637 23.7271'
      stroke='white'
      strokeWidth='4.09091'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='67.9093' cy='10.6363' r='4.09091' fill='#5BC586' />
    <circle cx='80.1821' cy='47.4545' r='2.45455' fill='#5BC586' />
    <circle cx='2.45455' cy='37.6362' r='2.45455' fill='#5BC586' />
    <circle cx='14.3181' cy='7.77268' r='1.22727' fill='#5BC586' />
    <defs>
      <linearGradient
        id='paint0_linear_3749_1000'
        x1='42.1367'
        y1='1.90735e-06'
        x2='42.5458'
        y2='54.8182'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='#5BC586' />
        <stop offset='1' stopColor='#5BC586' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>
)
